import React from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import UserLayout from "src/layouts/UserLayout";

const Messages = () => {
  return (
    <UserLayout>
      <Card sx={{ p: 20 }}>
        <CardContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img alt="Brevemente" src="/images/pages/misc-under-maintenance.png" height={200} />
          </div>
          <div style={{ display: "flex", justifyContent: "center", paddingTop: 30 }}>
            <Typography variant="h5" sx={{ mb: 2.5, fontSize: "1.5rem !important" }}>
              Brevemente 🚧
            </Typography>
          </div>
        </CardContent>
      </Card>
    </UserLayout>
  );
};

export default Messages;
