// ** React Imports
import { useState } from "react";

import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
// ** MUI Components
import Card from "@mui/material/Card";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Fade from "@mui/material/Fade";

// ** Icons Imports
import EyeOutline from "mdi-material-ui/EyeOutline";
import ChevronLeft from "mdi-material-ui/ChevronLeft";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";

// ** Third Party Imports
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SalesParkLogo from "src/@core/components/salespark/SalesParkLogo";
import VersionSmall from "src/@core/components/salespark/VersionSmall";

// ** Hooks
import { useAuth } from "src/hooks/useAuth";
import { useSettings } from "src/@core/hooks/useSettings";

// ** Layout Import
import BlankLayout from "src/@core/layouts/BlankLayout";

// ** Demo Imports
import FooterIllustrationsV2 from "src/views/pages/auth/FooterIllustrationsV2";

yup.setLocale({
  string: {
    email: "É necessário indicar um e-mail válido",
  },
  mixed: {
    required: "Este campo é obrigatório",
  },
});

const ResetPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // ** Hooks
  const token = new URLSearchParams(location.search).get("token");
  const auth = useAuth();
  const theme = useTheme();
  const { settings } = useSettings();
  const hidden = useMediaQuery(theme.breakpoints.down("md"));
  const [showError, setShowError] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);

  const [values, setValues] = useState({
    newPassword: "",
    showNewPassword: false,
    confirmNewPassword: "",
    showConfirmNewPassword: false,
  });

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  const handleClickShowConfirmNewPassword = () => {
    setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword });
  };

  const InvalidTokenNewRequest = ({ title }) => {
    return (
      <>
        {title}.
        <br />
        <Link component={RouterLink} to="/forgot-password">
          Clica aqui para um novo pedido de recuperação de senha.
        </Link>
      </>
    );
  };

  const ParseResetPasswordError = ({ reason }) => {
    switch (reason) {
      case "missing_token":
        return <InvalidTokenNewRequest title="O link não parece ser válido" />;
        break;
      case "invalid_token_length":
        return <InvalidTokenNewRequest title="O link não parece ser válido" />;
        break;
      case "error_getting_data":
        return "Ocorreu um erro ao obter os dados. Efetua novamente o pedido de recuperação de senha.";
        break;
      case "invalid_token":
        return <InvalidTokenNewRequest title="O link não parece ser válido" />;
        break;
      case "token_expired":
        return <InvalidTokenNewRequest title="Este link expirou" />;
        break;
      case "error_updating_user":
        return "Ocorreu um erro ao atualizar os dados. Efetua novamente o pedido de recuperação de senha.";
        break;

      default:
        return "Ocorreu um erro desconhecido. Efetua novamente o pedido de recuperação de senha.";
        break;
    }
  };

  // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  const schema = yup.object().shape({
    password: yup
      .string()
      .required()
      .min(4, "Tem de ter pelo menos 4 caracteres")
      .max(16, "Deve ter no máximo 16 caracteres"),
    // .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/, "Deve conter pelo menos 6 caracteres."),
    repeat_password: yup
      .string()
      .required()
      .min(4, "Tem de ter pelo menos 4 caracteres")
      .max(16, "Deve ter no máximo 16 caracteres")
      .oneOf([yup.ref("password"), null], "As senhas não coincidem"),
  });

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setShowError(false);
    setErrorDetails(null);

    auth.resetPassword(
      { ...data, token: token },
      (a) => {
        setShowError(true);
        setErrorDetails(a?.response?.data?.reason);
      },
      () => {
        navigate("/login");
      }
    );
  };

  return (
    <BlankLayout>
      <Box className="content-center">
        <Card sx={{ zIndex: 1, width: "100%", maxWidth: "520px" }}>
          <CardContent sx={{ p: (theme) => `${theme.spacing(6.5, 7, 6.5)} !important` }}>
            <SalesParkLogo />
            {showError && (
              <Fade in={showError} {...(showError ? { timeout: 700 } : {})}>
                <Box sx={{ pt: 0, mb: 5 }}>
                  <Alert severity="error">
                    <AlertTitle>Oops!</AlertTitle>
                    <ParseResetPasswordError reason={errorDetails} />
                  </Alert>
                </Box>
              </Fade>
            )}
            <Box sx={{ mb: 6 }}>
              <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
                Definir Nova Senha 🔒
              </Typography>
              <Typography variant="body2">Cria uma nova senha e repete a mesma para confirmar</Typography>
            </Box>
            <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel htmlFor="reset-password" error={Boolean(errors.password)}>
                  Senha
                </InputLabel>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      value={value}
                      onBlur={onBlur}
                      label="Senha"
                      onChange={onChange}
                      id="reset-password"
                      error={Boolean(errors.password)}
                      type={values.showNewPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => handleClickShowNewPassword(!values.showNewPassword)}
                          >
                            {values.showNewPassword ? <EyeOutline /> : <EyeOffOutline />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errors.password && (
                  <FormHelperText sx={{ color: "error.main" }} id="">
                    {errors.password.message}
                  </FormHelperText>
                )}
              </FormControl>

              <FormControl fullWidth sx={{ mb: 4 }}>
                <InputLabel htmlFor="reset-repeat-password" error={Boolean(errors.repeat_password)}>
                  Repetir Senha
                </InputLabel>
                <Controller
                  name="repeat_password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <OutlinedInput
                      value={value}
                      onBlur={onBlur}
                      label="Repetir Senha"
                      onChange={onChange}
                      id="reset-repeat-password"
                      error={Boolean(errors.repeat_password)}
                      type={values.showConfirmNewPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => handleClickShowConfirmNewPassword(!values.showConfirmNewPassword)}
                          >
                            {values.showConfirmNewPassword ? <EyeOutline /> : <EyeOffOutline />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errors.repeat_password && (
                  <FormHelperText sx={{ color: "error.main" }} id="">
                    {errors.repeat_password.message}
                  </FormHelperText>
                )}
              </FormControl>

              <Button fullWidth size="large" type="submit" variant="contained" sx={{ mb: 5.25 }}>
                Definir Nova Senha
              </Button>
              <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Link passHref href="/login">
                  <Typography
                    component={MuiLink}
                    sx={{ display: "flex", alignItems: "center", color: "primary.main", justifyContent: "center" }}
                  >
                    <ChevronLeft sx={{ mr: 1.5, fontSize: "2rem" }} />
                    <span>Voltar ao Login</span>
                  </Typography>
                </Link>
              </Typography>
            </form>
          </CardContent>
          <VersionSmall />
        </Card>
        <FooterIllustrationsV2 />
      </Box>
    </BlankLayout>
  );
};

export default ResetPasswordPage;
