import React from 'react'

import BookOpenVariant from 'mdi-material-ui/BookOpenVariant'
import Star from 'mdi-material-ui/Star'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

function Stats({ lessons, loading }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
      <Box sx={{ display: 'flex', ml: 2 }}>
        <BookOpenVariant color='secondary' />
        <Typography variant='subtitle2' sx={{ pl: 1, pt: 0.4 }}>
          {loading ? (
            <Skeleton width='50px' animation='wave' />
          ) : (
            <>{lessons === 1 ? `${lessons} Aula` : `${lessons} Aulas`}</>
          )}
        </Typography>
      </Box>
      {/* <Box sx={{ display: 'flex', mr: 2 }}>
        <Star sx={{ color: '#f9d71c' }} />
        <Typography variant='subtitle2' sx={{ pl: 1, pt: 0.4 }}>
          {loading ? <Skeleton width='30px' animation='wave' /> : '4.2(44)'}
        </Typography>
      </Box> */}
    </Box>
  )
}

export default Stats
