import axios from "axios";

const api_request = (auth_data) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      "x-email": auth_data.email,
      Authorization: auth_data.token,
    },
  });
};

const checkAuth = (resp) => {
  //TODO: #6:
  //Impement on components on catch: If status is 401 execute Google Signout
  try {
    if (resp.response.status === 401) window.location.reload();
  } catch (error) {}
};

export const courses_list = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/users/courses")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const course_get = async (auth_data, course_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/users/courses/${course_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

// export const course_videos_information_post = async (auth_data, videos) => {
//   return new Promise((resolve, reject) => {
//     api_request(auth_data)
//       .post(`/users/courses/video/information`, videos)
//       .then(res => resolve(res))
//       .catch(err => {
//         checkAuth(err)
//         reject({ ...err })
//       })
//   })
// }

export const course_video_signed_url_post = async (auth_data, video_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/users/video-signed-url/${video_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const course_update = async (auth_data, course_id, action, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/users/courses/${course_id}/${action}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const course_lessons_status_get = async (auth_data, course_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/users/courses/${course_id}/lessons-status`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
