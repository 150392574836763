import React, { useState, useEffect } from "react";
import { MobileView } from "react-device-detect";
// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CourseCard from "src/layouts/components/CourseCard";
// ** Layout Import
import UserLayout from "src/layouts/UserLayout";
import Spinner from "src/@core/components/spinner";
import { courses_list } from "src/api/courses";

// ** Hooks
import { useAuth } from "src/hooks/useAuth";

const Home = (props) => {
  // ** Hooks
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const [errorLoadingData, setErrorLoadingData] = useState(false);

  useEffect(() => {
    if (auth?.user) loadCourses();
  }, []);

  const loadCourses = async () => {
    const response = await dbGetCoursesList(auth.user);

    if (!response.status) {
      setErrorLoadingData(true);
    } else {
      setCourses(response.data);
    }
    setIsLoading(false);
  };

  const dbGetCoursesList = (auth_data) => {
    return new Promise((resolve) => {
      try {
        courses_list(auth_data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const CourseListLoading = () => {
    return Array.apply(null, { length: 4 }).map((e, i) => <CourseCard key={i} loading={true} />);
  };

  const NoCourses = () => {
    return (
      <div style={{ padding: 40 }}>
        <div style={{ padding: 20 }}>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Oops! Não existe nenhum curso disponível na tua conta...
          </Typography>
        </div>
      </div>
    );
  };

  const CoursesList = ({ data }) => {
    if (!data) return <CourseListLoading />;

    if (data.length === 0) return <NoCourses />;

    return data.map((item, i) => <CourseCard key={i} data={item} />);
  };

  return (
    <UserLayout>
      {/* <MobileView>
        <Alert severity="warning">Ainda não existe suporte completo a telemóveis. Brevemente.</Alert>
      </MobileView> */}
      {isLoading && <Spinner />}
      {errorLoadingData && (
        <Card sx={{ mt: 0 }}>
          <Box sx={{ mt: 30, mb: 30, ml: 15, mr: 15 }}>
            <Alert severity="error">
              <AlertTitle>Oops!</AlertTitle>
              Ocorreu um erro ao carregar os cursos. Tenta novamente mais tarde.
              <br />
              <br />
              Caso o erro persista, entra em contato com o suporte técnico através do e-mail:{" "}
              <strong>suporte@salepark.io</strong>
            </Alert>
          </Box>
        </Card>
      )}
      {!isLoading && !errorLoadingData && (
        <Card sx={{ mt: 0 }}>
          <Box sx={{ m: 4 }}>
            <Typography variant="h5">Meus cursos</Typography>
            <Grid container spacing={8} sx={{ mt: 1, mb: 1 }}>
              <CoursesList data={courses} />
            </Grid>
          </Box>
        </Card>
      )}
    </UserLayout>
  );
};

export default Home;
