import React, { useState, useEffect, useRef } from "react";
import LinearProgress from "@mui/material/LinearProgress";
const aspectRatioClass = "ar_1_6-9";
const isSmallDevice = window.innerWidth < 1400;

// TODO: Should come in video data
const LIBRARY_ID = 133254;
const VideoLessonBunny = ({
  videoData,
  course,
  videos_info,
  details,
  updateVideoCurrentTimeHandler,
  progressHandler,
}) => {
  const [playerLoaded, setPlayerLoaded] = useState(false);
  const [url, setUrl] = useState(undefined);
  useEffect(() => {
    if (details) {
      if (details?.video_origin === "library") {
        setPlayerLoaded(true);
        //`https://iframe.mediadelivery.net/embed/${videoData?.libraryId}/${videoData?.video_id}?token=${videoData?.hash}&expires=${videoData?.expirationTime}&autoplay=false&loop=false&muted=false&preload=true`

        setUrl(
          `https://iframe.mediadelivery.net/embed/${LIBRARY_ID}/${videoData?.video_id}?autoplay=false&loop=false&muted=false&preload=true`
        );
      }
    }
  }, [details]);
  //console.log("URL", url);
  return (
    <>
      <div className="lesson-video-wrapper" style={{ borderRadius: 0, height: "100%", position: "inherit" }}>
        {url === undefined && (
          <div className={`lesson-video-container ${isSmallDevice ? "ar-full" : "ar_1_6-9"} video-loading`}>
            <LinearProgress color="inherit" />
          </div>
        )}
        {url !== undefined && playerLoaded && (
          <div
            className={`lesson-video-container ${isSmallDevice ? "ar-full" : "ar_1_6-9"}`}
            id="lesson-video-container"
          >
            <div
              className={`${isSmallDevice ? "ar-full" : "ar_1_6-9"}`}
              style={{ position: "relative", paddingTop: "56.25%", display: "flex" }}
            >
              <iframe
                title={videoData?.title}
                src={url}
                loading="lazy"
                style={{ border: "none", position: "absolute", top: 0, height: "100%", width: "100%" }}
                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                allowFullScreen={true}
              ></iframe>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default VideoLessonBunny;
