import React from "react";
import CheckboxBlankCircleOutline from "mdi-material-ui/CheckboxBlankCircleOutline";
import CheckboxMarkedCircle from "mdi-material-ui/CheckboxMarkedCircle";
// ** MUI Imports
import Checkbox from "@mui/material/Checkbox";
import Typo from "src/@core/components/typo";
import Tooltip from "@mui/material/Tooltip";

import { useTheme } from "@mui/material/styles";
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";
import { LessonLength, LessonInfo, AttachmentsInfo } from "./helper";

// ** Hooks
import { useAuth } from "src/hooks/useAuth";

function Lessons({
  videos_info,
  chapter,
  lessons,
  actualChapter,
  actualLesson,
  lessonStatusHandler,
  activeLessonHandler,
  onShowAttachmentsClick,
  lessonsStatus,
  isChapterAvailable,
  isSmallDevice = false,
  showLessonNumbers,
}) {
  const auth = useAuth();
  const theme = useTheme();
  //console.log("Lessons -> lessons", showLessonNumbers);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const selectedColor = theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black;

  return (
    <div style={{ display: "block" }} className="lessons-container">
      {lessons.map((lesson, index) => {
        const isCompleted = lessonsStatus?.find((el) => el.lesson_id === lesson.lesson_id)?.completed;
        const activeLesson = lesson.lesson_id === actualLesson;

        return (
          <div
            key={index}
            // style={{
            //   backgroundColor: lesson.lesson_id === actualLesson ? hexToRGBA(selectedColor, 0.1) : "",
            // }}
            className={
              isSmallDevice
                ? `mobile-lesson-item ${activeLesson ? "active" : ""}`
                : `lesson-item ${activeLesson ? "active" : ""}`
            }
            onClick={() => {
              activeLessonHandler(chapter, lesson.lesson_id);
              scrollToTop();
            }}
          >
            <div className={isSmallDevice ? "mobile-lesson-item-container" : "lesson-item-container"}>
              <Tooltip title={isCompleted ? "Desmarcar como concluída" : "Marcar como concluída"} placement="right">
                {/* <CheckboxBlankCircleOutline sx={{ mr: 1.5, fontSize: "1.5rem" }} disabled /> */}
                <Checkbox
                  icon={<CheckboxBlankCircleOutline sx={{ fontSize: "1.5rem" }} />}
                  checkedIcon={<CheckboxMarkedCircle sx={{ fontSize: "1.5rem" }} />}
                  disabled={!isChapterAvailable}
                  disableRipple
                  checked={isCompleted}
                  className={
                    isSmallDevice
                      ? `mobile-chk-lesson-status ${isCompleted ? "checked" : ""}`
                      : `chk-lesson-status ${isCompleted ? "checked" : ""}`
                  }
                  name="basic-unchecked"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    lessonStatusHandler(chapter, lesson.lesson_id, e.target.checked);
                  }}
                />
              </Tooltip>

              <div style={{ display: "block" }}>
                <Typo align={"left"} append={<AttachmentsInfo lesson={lesson} onClick={onShowAttachmentsClick} />}>
                  <div
                    className={`${isSmallDevice ? "mobile-lesson-title" : "lesson-title"} ${
                      lesson.lesson_id === actualLesson ? "active" : ""
                    }`}
                  >
                    <LessonInfo lesson={lesson} />{" "}
                    <div>
                      {showLessonNumbers && <span>{index + 1}. </span>}
                      {lesson.title}
                    </div>
                  </div>
                </Typo>
                {isChapterAvailable && <LessonLength videos_info={videos_info} lesson={lesson} />}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Lessons;
