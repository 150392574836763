import axios from "axios";

const api_request = (auth_data) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      "x-email": auth_data.email,
      Authorization: auth_data.token,
    },
  });
};

const checkAuth = (resp) => {
  //TODO: #6:
  //Impement on components on catch: If status is 401 execute Google Signout
  try {
    if (resp.response.status === 401) window.location.reload();
  } catch (error) {}
};

export const comments_list = async (auth_data, course_id, chapter_id, lesson_id, order) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get(`/comments/lessons/${course_id}/${chapter_id}/${lesson_id}`, {
        params: { order: order },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_create = async (
  auth_data,
  course_id,
  chapter_id,
  lesson_id,
  payload,
  is_replay,
  parent_id
) => {
  return new Promise((resolve, reject) => {
    let url = `/comments/lessons/${course_id}/${chapter_id}/${lesson_id}`;
    if (is_replay) url += `?action=reply&parent_id=${parent_id}`;
    api_request(auth_data)
      .post(url, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_update = async (auth_data, lesson_id, comment_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .put(`/comments/lessons/${lesson_id}/${comment_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_delete = async (auth_data, course_id, chapter_id, lesson_id, comment_id) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .delete(`/comments/lessons/${course_id}/${chapter_id}/${lesson_id}/${comment_id}`)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const comment_votes_post = async (auth_data, course_id, chapter_id, lesson_id, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post(`/comments/lessons/votes/${course_id}/${chapter_id}/${lesson_id}`, payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
