// ** Header da página para visualização de vídeos

import React from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import ChevronLeft from "mdi-material-ui/ChevronLeft";
import Bug from "mdi-material-ui/Bug";
import Fab from "@mui/material/Fab";
// ** Components
import ModeToggler from "src/@core/layouts/components/shared-components/ModeToggler";
import UserDropdown from "src/layouts/components/UserDropdown";
//import Gleap from "gleap";
const isMobile = window.innerWidth <= 760;

function AppBarVideo({ title, settings, saveSettings, collapsedList, whiteLogo, darkLogo }) {
  // const GleapReportBug = () => (
  //   <>
  //     {!isMobile && (
  //       <div style={{ marginRight: 10 }}>
  //         <Tooltip title="Reportar um erro encontrado." placement="bottom">
  //           <Button
  //             sx={{ minWidth: 38 }}
  //             color="error"
  //             variant="outlined"
  //             size="small"
  //             onClick={() =>
  //               Gleap.startFeedbackFlow("bugreporting", {
  //                 hideBackButton: false,
  //               })
  //             }
  //           >
  //             <Bug />
  //           </Button>
  //         </Tooltip>
  //       </div>
  //     )}
  //     {isMobile && collapsedList && (
  //       <div style={{ marginRight: 5 }}>
  //         <Fab
  //           color="error"
  //           aria-label="Voltar"
  //           size="small"
  //           style={{ width: "30px", height: "30px", minHeight: "30px", lineHeight: "25px", marginLeft: "1rem" }}
  //           onClick={() =>
  //             Gleap.startFeedbackFlow("bugreporting", {
  //               hideBackButton: false,
  //             })
  //           }
  //         >
  //           <Bug />
  //         </Fab>
  //       </div>
  //     )}
  //   </>
  // );

  return (
    <Box className="video-layout-top-bar">
      <Box className="actions-left" sx={{ mr: 2, display: "flex", alignItems: "center" }}>
        {!isMobile && (
          <Tooltip title="Voltar à lista de cursos">
            <Button variant="outlined" href="/home" sx={{ ml: "1rem" }} size="small">
              <ChevronLeft />
            </Button>
          </Tooltip>
        )}

        {isMobile && (
          <Fab
            color="default"
            aria-label="Voltar"
            href="/home"
            size="small"
            style={{
              width: "30px",
              minWidth: "30px",
              height: "30px",
              minHeight: "30px",
              lineHeight: "25px",
              marginLeft: ".5rem",
            }}
          >
            <ChevronLeft />
          </Fab>
        )}
        {whiteLogo && darkLogo && (
          <div style={{ marginTop: 10, marginLeft: 15 }}>
            <img className="app-bar-logo" src={settings.mode === "light" ? whiteLogo : darkLogo} alt="logo" />
          </div>
        )}

        <Box sx={{ ml: 4 }}>
          {!isMobile && (
            <Typography variant="h6" style={{ fontSize: 18 }}>
              {title}
            </Typography>
          )}

          {isMobile && !whiteLogo && (
            <div class="parent-div" style={{ fontSize: 15, display: "flex" }}>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
                variant="h6"
              >
                {title}
              </Typography>
            </div>
          )}
        </Box>
      </Box>

      <Box className="actions-right" sx={{ mr: "1rem", display: "flex", alignItems: "center" }}>
        {/* <GleapReportBug /> */}
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
}

export default AppBarVideo;
