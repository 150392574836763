import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import CheckboxMarkedCircle from "mdi-material-ui/CheckboxMarkedCircle";

const ChapterProgress = ({ completedPercentage }) => {
  return (
    <div className="chapter-progress-container">
      {completedPercentage === 100 ? (
        <>
          <CheckboxMarkedCircle style={{ color: "#09af32", marginRight: 5, fontSize: "1.2rem" }} />
          <Typography className="chapter-progress-text" variant="body2">
            Terminado
          </Typography>
        </>
      ) : (
        <>
          <Typography className="chapter-progress-text" variant="body2">
            {completedPercentage}%
          </Typography>
          <LinearProgress
            className="chapter-progress"
            color={completedPercentage === 100 ? "success" : "primary"}
            variant="determinate"
            value={completedPercentage}
          />
        </>
      )}
    </div>
  );
};

export default ChapterProgress;
