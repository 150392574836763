// **Zona para o vídeo player

import React, { useEffect, useState } from "react";
import VideoLibraryLesson from "./VideoLibraryLesson";
import TextLesson from "./TextLesson";
import AudioLesson from "./AudioLesson";
import VideoLesson from "./VideoLesson";
import Button from "@mui/material/Button";
import ChevronRight from "mdi-material-ui/ChevronRight";
import CheckCircleOutline from "mdi-material-ui/CheckCircleOutline";
import Card from "@mui/material/Card";
import Countdown, { zeroPad } from "react-countdown";
import Chip from "@mui/material/Chip";
import moment from "moment/moment";
import { locale } from "src/lang/locale/moment_pt_PT";
moment.locale("pt", locale);
const avaliableSoonLessonImage = "/images/avaliable-soon.png";

const ChapterAvaliableIn = ({ chapter, courseAddedAt, courseEndDate, style }) => {
  if (!chapter) return <></>;

  if (chapter.status_code === 0) return <></>;
  if (chapter.status_code === 1) return <></>;
  let fromNow = undefined;
  let endDate = undefined;

  // Dripping
  if (chapter.status_code === 2 && chapter.drip_days !== undefined) {
    const avaliableDate = moment.utc(courseAddedAt).add(chapter.drip_days, "days");
    if (moment.utc().isAfter(avaliableDate)) return <></>;

    fromNow = moment.utc(avaliableDate).fromNow(true);
  }

  // Avaliable On
  if (chapter.status_code === 3 && chapter.avaliable_on !== undefined) {
    if (moment.utc().isAfter(chapter.avaliable_on)) return <></>;

    fromNow = moment.utc(chapter.avaliable_on).fromNow(true);
  }

  if (chapter.avaliable_on !== undefined && chapter.avaliable_on !== null) {
    // Check if difference is less than 1 mounth
    if (moment.utc().diff(moment.utc(chapter.avaliable_on), "days") < 30) {
      return (
        <div style={style}>
          <h2>Módulo disponível dentro de:</h2>
          <Countdown date={moment.utc(chapter.avaliable_on).local().toDate()} renderer={renderer} />
        </div>
      );
    }
  }
  if (fromNow !== undefined) {
    return (
      <div style={style}>
        <h2>{`Módulo disponível dentro de ${fromNow}`}</h2>
        {/* <Countdown date={moment.utc(chapter.avaliable_on).local().toDate()} renderer={renderer} /> */}
      </div>
    );
  }
  return <></>;
};

const renderer = ({ days, hours, minutes, seconds }) => {
  return (
    <div>
      <table style={{ marginLeft: "auto", marginRight: "auto" }}>
        <tbody>
          <tr className="countdown-table-hours">
            <td>&nbsp;&nbsp;{days}&nbsp;&nbsp;</td>
            <td></td>
            <td>{zeroPad(hours)}</td>
            <td>:</td>
            <td>{zeroPad(minutes)}</td>
            <td>:</td>
            <td>{zeroPad(seconds)}</td>
          </tr>
          <tr className="countdown-table-labels">
            <td>{days > 1 ? "Dias" : "Dia"}</td>
            <td></td>
            <td>{hours > 1 ? "Horas" : "Hora"}</td>
            <td></td>
            <td>{minutes > 1 ? "Minutos" : "Minuto"}</td>
            <td></td>
            <td>{seconds > 1 ? "Segundos" : "Segundo"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function LessonContent({
  course,
  videos_info,
  details,
  updateVideoCurrentTimeHandler,
  updateAudioCurrentTimeHandler,
  lessonsStatus,
  updateLessonAsCompleted,
  moveToNextLesson,
  isLastChapter,
  isLastLesson,
  courseAddedAt,
  courseEndDate,
  activeChapter,
}) {
  const [lessonAlmostCompleted, setLessonAlmostCompleted] = useState(false);
  const actualLessonCompleted = lessonsStatus?.find((el) => el.lesson_id === details?.lesson_id)?.completed;
  const [showNextLessonButton, setShowNextLessonButton] = useState(false);

  useEffect(() => {
    setShowNextLessonButton(false);
    setLessonAlmostCompleted(false);
  }, [details]);

  //if (!actualLessonCompleted) setLessonAlmostCompleted(true)

  const progressHandler = (currentTime, duration, ended, showButtonSecBefore = 8) => {
    if (ended) {
      setTimeout(() => {
        setShowNextLessonButton(true);
      }, 1000);

      return;
    }
    const _almostIs = currentTime >= duration - showButtonSecBefore;
    if (_almostIs && !lessonAlmostCompleted) setLessonAlmostCompleted(true);
  };

  const _isChapterAvailable = (chapter, courseAddedAt) => {
    if (chapter.status_code === 0) return false;
    if (chapter.status_code === 1) return true;

    // Dripping
    if (chapter.status_code === 2 && chapter.drip_days !== undefined) {
      const avaliableDate = moment.utc(courseAddedAt).add(chapter.drip_days, "days");

      if (moment.utc().isAfter(avaliableDate)) return true;
    }

    // Avaliable On
    if (chapter.status_code === 3 && chapter.avaliable_on !== undefined) {
      if (moment.utc().isAfter(chapter.avaliable_on)) return true;
    }

    return false;
  };

  const isChapterAvailable = _isChapterAvailable(activeChapter, courseAddedAt);

  return (
    <>
      {/* {isLastLesson && isChapterAvailable && (
        <div className="last-lesson">
          <Alert variant="filled" severity="success">
            <AlertTitle>
              <strong>Parabéns</strong>
            </AlertTitle>
            Já terminaste todas as aulas deste curso.
          </Alert>
        </div>
      )} */}
      {!isLastLesson && isChapterAvailable === true && (
        <div className="next-lesson">
          {!actualLessonCompleted && lessonAlmostCompleted && (
            <Button variant="contained" color="secondary" onClick={updateLessonAsCompleted}>
              Marcar aula como concluída <CheckCircleOutline />
            </Button>
          )}
          {actualLessonCompleted && showNextLessonButton && (
            <Button variant="contained" color="secondary" onClick={moveToNextLesson}>
              Ir para a próxima aula <ChevronRight />
            </Button>
          )}
        </div>
      )}
      {!isChapterAvailable === true && (
        <div className="lesson-text-wrapper" style={{ borderRadius: 0, height: "100%", position: "inherit" }}>
          {activeChapter?.image !== null && activeChapter?.image !== undefined && (
            <div className="countdown-text">
              <Chip
                style={{ padding: 10, height: "auto" }}
                label={<ChapterAvaliableIn chapter={activeChapter} courseAddedAt={courseAddedAt} />}
                color="secondary"
              />
            </div>
          )}
          <div className="lesson-container-soon" id="lesson-container-soon">
            {activeChapter?.image !== null && activeChapter?.image !== undefined ? (
              <div
                className="cover"
                style={{
                  backgroundImage: `url("${activeChapter?.image}")`,
                }}
              ></div>
            ) : (
              <div className="cover">
                <Card>
                  <div className="countdown-card">
                    <ChapterAvaliableIn
                      chapter={activeChapter}
                      courseAddedAt={courseAddedAt}
                      courseEndDate={courseEndDate}
                    />
                  </div>
                </Card>
              </div>
            )}
          </div>
        </div>
      )}
      {isChapterAvailable === true && details?.lesson_type === "video" && details?.video_origin === "library" && (
        <VideoLibraryLesson
          course={course}
          videos_info={videos_info}
          details={details}
          updateVideoCurrentTimeHandler={updateVideoCurrentTimeHandler}
          progressHandler={progressHandler}
        />
      )}
      {isChapterAvailable === true && details?.lesson_type === "video" && details?.video_origin === "external" && (
        <VideoLesson
          course={course}
          videos_info={videos_info}
          details={details}
          updateVideoCurrentTimeHandler={updateVideoCurrentTimeHandler}
          progressHandler={progressHandler}
        />
      )}
      {isChapterAvailable === true && details?.lesson_type === "text" && (
        <TextLesson
          course={course}
          videos_info={videos_info}
          details={details}
          updateVideoCurrentTimeHandler={updateVideoCurrentTimeHandler}
          progressHandler={progressHandler}
        />
      )}
      {isChapterAvailable === true && details?.lesson_type === "audio" && (
        <AudioLesson
          details={details}
          updateAudioCurrentTimeHandler={updateAudioCurrentTimeHandler}
          progressHandler={progressHandler}
        />
      )}
    </>
  );
}

export default LessonContent;
