// ** Icon imports
import FormatListBulletedSquare from "mdi-material-ui/FormatListBulletedSquare";
import MessageText from "mdi-material-ui/MessageText";
import VideoBox from "mdi-material-ui/VideoBox";

const navigation = () => {
  return [
    {
      title: "Os meus Cursos",
      icon: VideoBox,
      path: "/home",
    },

    {
      title: "Mensagens",
      icon: MessageText,
      path: "/messages",
      action: "read",
      subject: "acl-page",
    },

    // {
    //   title: 'Lista de Desejos',
    //   icon: FormatListBulletedSquare,
    //   path: '/wishlist',
    //   action: 'read',
    //   subject: 'acl-page'
    // }
  ];
};

export default navigation;
