// ** Zona onde vão ser postos os comentários
import React, { useMemo, useState } from "react";

// ** MUI Import
import Box from "@mui/material/Box";

import { comment_votes_post, comment_delete } from "src/api/comments";
import CommentsInsert from "./CommentsInsert";
import CommentAvatar from "./Components/CommentAvatar";
import CommentInfo from "./Components/CommentInfo";
import CommentActions from "./Components/CommentActions";
import ModalDeleteComment from "./Components/ModalDeleteComment";

// ** Third Party Components
import toast from "react-hot-toast";

function CommentView({ auth, courseId, chapterId, lessonId, comment, onCommentUpdate, userCanReply }) {
  const [deleteCommentModal, setDeleteCommentModal] = useState({ visible: false, id: null });
  const [replyCommentModal, setReplyCommentModal] = useState({ visible: false });

  if (comment.status !== "approved" && auth.user.user_id !== comment.user_id) return null;

  const handleCommentDelete = async (comment_id) => {
    try {
      setDeleteCommentModal({ visible: false, id: null });
      const handleDeleteResponse = await dbCommentDelete(auth.user, courseId, chapterId, lessonId, comment_id);
      if (!handleDeleteResponse.status) {
        toast.error("Oops... Erro ao apagar o comentário...", {
          position: "top-right",
          duration: 5000,
        });
        return;
      }
      onCommentUpdate(handleDeleteResponse.data);
    } catch (e) {}
  };

  const handleCommentVote = async (action, comment_id) => {
    try {
      const handleVoteResponse = await dbCommentVote(auth.user, courseId, chapterId, lessonId, {
        location_id: `${courseId}-${chapterId}-${lessonId}`,
        comment_id: comment_id,
        user_id: auth.user.user_id,
        action: action,
      });
      if (!handleVoteResponse.status) {
        toast.error("Oops... Erro ao adicionar o 'Gosto'...", {
          position: "top-right",
          duration: 5000,
        });
        return;
      }
      onCommentUpdate(handleVoteResponse.data);
    } catch (e) {}
  };

  const dbCommentVote = (auth, courseId, chapterId, lessonId, payload) => {
    return new Promise((resolve) => {
      try {
        comment_votes_post(auth, courseId, chapterId, lessonId, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbCommentDelete = (auth, courseId, chapterId, lessonId, comment_id) => {
    return new Promise((resolve) => {
      try {
        comment_delete(auth, courseId, chapterId, lessonId, comment_id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", mb: 3, justifyContent: "flex-start" }}>
        <CommentAvatar user={comment?.user} />
        <Box sx={{ mt: 2 }}>
          <CommentInfo auth={auth} comment={comment} setDeleteCommentModal={setDeleteCommentModal} />
          {comment?.status === "approved" && (
            <Box sx={{ mt: 0, ml: 2, mb: 1 }}>
              <CommentActions
                userCanReply={userCanReply}
                auth={auth}
                comment={comment}
                handleCommentVote={handleCommentVote}
                setReplyCommentModal={setReplyCommentModal}
                isReply={false}
              />

              {replyCommentModal.visible && (
                <CommentsInsert
                  parentId={comment._id}
                  courseId={courseId}
                  chapterId={chapterId}
                  lessonId={lessonId}
                  comment={comment}
                  isReplay={true}
                  auth={auth}
                  onClose={() => setReplyCommentModal({ visible: false })}
                  onAddNewComment={(e) => onCommentUpdate()}
                />
              )}

              {comment?.replies?.map((reply, index) => (
                <Box key={reply._id} sx={{ display: "flex", mb: 1, justifyContent: "flex-start" }}>
                  <CommentAvatar user={reply?.user} />

                  <Box sx={{ mt: 2, ml: 2 }}>
                    <CommentInfo auth={auth} comment={reply} setDeleteCommentModal={setDeleteCommentModal} />

                    <Box sx={{ mt: 1, ml: 2, mb: 2 }}>
                      <CommentActions
                        auth={auth}
                        comment={reply}
                        handleCommentVote={handleCommentVote}
                        setReplyCommentModal={setReplyCommentModal}
                        isReply={true}
                      />
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
      <ModalDeleteComment
        deleteCommentModal={deleteCommentModal}
        setDeleteCommentModal={setDeleteCommentModal}
        handleCommentDelete={handleCommentDelete}
      />
    </>
  );
}

export default CommentView;
