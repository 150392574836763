// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'

// ** Icons Imports
import MenuIcon from 'mdi-material-ui/Menu'

// ** Components
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/layouts/components/UserDropdown'

import Bug from 'mdi-material-ui/Bug'
import Button from '@mui/material/Button'

import Tooltip from '@mui/material/Tooltip'
//import Gleap from 'gleap'

const AppBarContent = props => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  // const GleapReportBug = () => (
  //   <div style={{ marginRight: 10 }}>
  //     <Tooltip title='Reportar um erro encontrado.' placement='bottom'>
  //       <Button
  //         sx={{ minWidth: 38 }}
  //         color='error'
  //         variant='outlined'
  //         size='small'
  //         onClick={() =>
  //           Gleap.startFeedbackFlow('bugreporting', {
  //             hideBackButton: false
  //           })
  //         }
  //       >
  //         <Bug />
  //       </Button>
  //     </Tooltip>
  //   </div>
  // )

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
            <MenuIcon />
          </IconButton>
        ) : null}
      </Box>
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {/* <GleapReportBug /> */}
        <ModeToggler settings={settings} saveSettings={saveSettings} />
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
