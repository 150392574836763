// ** React Imports
import { useState, useEffect } from "react";
import React from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";

import ProfileView from "./LeftProfileView/ProfilePreview/ProfileView";
import TabOptions from "./RightProfileView/Tab/TabOptions";

function MainUserLayout({ id }) {
  // ** State

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={5} lg={4}>
        <ProfileView />
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <TabOptions />
      </Grid>
    </Grid>
  );
}

export default MainUserLayout;
