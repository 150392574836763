// ** React Imports
import { useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";

// ** MUI Components
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";

import CardContent from "@mui/material/CardContent";

// ** Icons Imports
import ChevronLeft from "mdi-material-ui/ChevronLeft";

// ** Third Party Imports
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SalesParkLogo from "src/@core/components/salespark/SalesParkLogo";
import VersionSmall from "src/@core/components/salespark/VersionSmall";

// ** Hooks
import { useAuth } from "src/hooks/useAuth";
import { useSettings } from "src/@core/hooks/useSettings";

// ** Layout Import
import BlankLayout from "src/@core/layouts/BlankLayout";

// ** Demo Imports
import FooterIllustrationsV2 from "src/views/pages/auth/FooterIllustrationsV2";

yup.setLocale({
  string: {
    email: "É necessário indicar um e-mail válido",
  },
  mixed: {
    required: "Este campo é obrigatório",
  },
});

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const ForgotPasswordPage = () => {
  // ** Hooks
  const auth = useAuth();
  const theme = useTheme();
  const { settings } = useSettings();
  const hidden = useMediaQuery(theme.breakpoints.down("md"));

  const [completed, setCompleted] = useState(false);

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    const { email } = data;
    auth.forgotPassword(
      { email },
      () => {
        setError("email", {
          type: "manual",
          message: "Oops, algo correu mal...",
        });
      },
      () => {
        setCompleted(true);
      }
    );
  };

  return (
    <BlankLayout>
      <Box className="content-center">
        <Card sx={{ zIndex: 1, width: "100%", maxWidth: "520px" }}>
          {!completed && (
            <CardContent sx={{ p: (theme) => `${theme.spacing(6.5, 7, 6.5)} !important` }}>
              <SalesParkLogo />
              <Box sx={{ mb: 6 }}>
                <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
                  Esqueceste-te da senha? 🔒
                </Typography>
                <Typography variant="body2">
                  Indica o teu endereço de e-mail para efetuar a recuperação.
                  <br /> <br />
                  <small>
                    <em>
                      Certifica-te que indicas o endereço de e-mail corretamente. Apenas recebes e-mail de
                      recuperação se o mesmo for válido.
                    </em>
                  </small>
                </Typography>
              </Box>
              <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoFocus
                        label="Endereço de e-mail"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        placeholder="O teu endereço de e-mail"
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText sx={{ color: "error.main" }}>{errors.email.message}</FormHelperText>
                  )}
                </FormControl>

                <Button fullWidth size="large" type="submit" variant="contained" sx={{ mb: 7 }}>
                  Confirmar
                </Button>
                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Link component={RouterLink} to="/login">
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "primary.main",
                        justifyContent: "center",
                      }}
                    >
                      <ChevronLeft sx={{ mr: 1.5, fontSize: "2rem" }} />
                      <span>Voltar ao Login</span>
                    </Typography>
                  </Link>
                </Typography>
              </form>
            </CardContent>
          )}
          {completed && (
            <CardContent sx={{ p: (theme) => `${theme.spacing(6.5, 7, 6.5)} !important` }}>
              <SalesParkLogo />
              <Box sx={{ mb: 6 }}>
                <Typography variant="h5" sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}>
                  Só falta mais um passo! 🎉
                </Typography>
                <Typography variant="body2" style={{ marginBottom: 30 }}>
                  Dentro de alguns segundos vais receber um e-mail com o link para definir uma nova password. <br />
                  <br />
                  <small>
                    Procura o e-mail na tua caixa de entrada ou na pasta de spam.
                    <br />O assunto do e-mail é "
                    <strong>
                      [SalesPark] {"<"}
                      <em>o teu nome</em>
                      {">"}, a tua recuperação de Senha
                    </strong>
                    ".
                  </small>
                </Typography>
                <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <Link component={RouterLink} to="/login">
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "primary.main",
                        justifyContent: "center",
                      }}
                    >
                      <ChevronLeft sx={{ mr: 1.5, fontSize: "2rem" }} />
                      <span>Voltar ao Login</span>
                    </Typography>
                  </Link>
                </Typography>
              </Box>
            </CardContent>
          )}
          <VersionSmall />
        </Card>
        <FooterIllustrationsV2 />
      </Box>
    </BlankLayout>
  );
};

export default ForgotPasswordPage;
