const getAvatarPicture = (name) => {
  if (name === undefined || name === null || name === "") {
    return "https://api.dicebear.com/7.x/initials/svg?seed=John+Doe";
  }

  const _name = name.replace(/ /g, "_").replace(/\s\s+/g, "-").replace(/#/g, "");

  // Outdated API Endpoint https://github.com/FBlade/SalesPark.io/issues/4304
  //return `https://avatars.dicebear.com/api/initials/${_name}.svg`

  return `https://api.dicebear.com/7.x/initials/svg?seed=${_name}`;
};

// Exports
export default getAvatarPicture;
