import React, { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Heart from "mdi-material-ui/Heart";
import CustomBadge from "src/@core/components/mui/badge";
import MessageReplyTextOutline from "mdi-material-ui/MessageReplyTextOutline";

const CommentActions = ({ auth, comment, handleCommentVote, setReplyCommentModal, isReply, userCanReply }) => {
  const theme = useTheme();
  const hasSelfVote = comment?.votes?.find((user) => user.user_id === auth.user.user_id);

  const canReply = () => {
    //isReply === false || userCanReply === true
    if (isReply === false && userCanReply === true) return true;
    if (userCanReply === true) return true;
    return false;
  };

  const MemoCommentActions = useMemo(
    () => (
      <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <>
          <Tooltip title={hasSelfVote ? "Remover Gosto" : "Gosto"} placement="top">
            <IconButton size="small" onClick={() => handleCommentVote(hasSelfVote ? "remove" : "add", comment._id)}>
              <Heart
                className={hasSelfVote ? "comment-vote-self" : "comment-vote"}
                sx={{
                  fontSize: "1.2rem",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Tooltip>
          {comment?.votes?.length > 0 && (
            <CustomBadge
              className="comment-votes-badge"
              sx={{ ml: 1 }}
              skin="light"
              color="error"
              badgeContent={comment?.votes?.length}
            />
          )}
        </>
        {canReply() === true && (
          <Typography
            className="comment-reply"
            sx={{
              color: "text.secondary",
              fontSize: "0.9rem",
              ml: 2,
              "&:hover": { color: theme.palette.primary.main },
              "&:hover svg": { color: theme.palette.primary.main },
              cursor: "pointer",
            }}
          >
            <div
              style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
              onClick={() => setReplyCommentModal({ visible: true })}
            >
              <MessageReplyTextOutline
                className="comment-reply"
                sx={{ color: "text.secondary", fontSize: "1.2rem", ml: 2, mr: 1 }}
              />
              Responder
            </div>
          </Typography>
        )}
      </div>
    ),
    [hasSelfVote, comment, handleCommentVote, setReplyCommentModal, isReply, theme]
  );

  return MemoCommentActions;
};

export default CommentActions;
