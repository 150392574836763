import React, { useState, Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

const parseTransactionId = (id) => {
  switch (id) {
    case "*offer*":
      return {
        title: "Produto oferecido pelo produtor.",
        description: "Este produto foi-te oferecido pelo produtor. Não houve qualquer transação financeira.",
      };

    case "*team*":
      return {
        title: "És membro da equipa.",
        description:
          "Este produto foi-te oferecido por seres membro da equipa. Não houve qualquer transação financeira.",
      };

    // case "*import*":
    //   return {
    //     title: "Produto importado de outra plataforma.",
    //     description:
    //       "Este produto foi importado de outra plataforma e não houve qualquer transação financeira com a SalesPark.",
    //   };

    default:
      return null;
  }
};

const TransactionInfo = ({ course }) => {
  const [modalInfo, setModalInfo] = useState({ visible: false });

  if (course === undefined || course === null) return null;

  console.log("course", course);
  const transactionInfoObj = parseTransactionId(course?.transaction_id);
  if (transactionInfoObj === null) return null;

  return (
    <div style={{ padding: 8 }}>
      <Alert
        severity="info"
        action={
          <Button type="button" variant="text" color="info" onClick={() => setModalInfo({ visible: true })}>
            Saber mais
          </Button>
        }
      >
        {transactionInfoObj?.title}
      </Alert>

      <Fragment>
        <Dialog
          open={modalInfo.visible}
          onClose={() => setModalInfo({ visible: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{course?.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>Motivo pelo qual tens acesso a este produto:</strong>
              <br />
              {transactionInfoObj?.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dialog-actions-dense">
            <Button onClick={() => setModalInfo({ visible: false })}>Compreendi</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    </div>
  );
};

export default TransactionInfo;
