import axios from "axios";

const api_request = (auth_data) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 60000,
    headers: {
      "Content-Type": "application/json",
      "x-email": auth_data.email,
      Authorization: auth_data.token,
    },
  });
};

const checkAuth = (resp) => {
  //TODO: #6:
  //Impement on components on catch: If status is 401 execute Google Signout
  try {
    if (resp.response.status === 401) window.location.reload();
  } catch (error) {}
};

export const file_get = async (auth_data, file) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/product-download/lessons/file", {
        params: { file: file, expires: 60 * 60 * 24 },
      })
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
