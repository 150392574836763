// ** React Imports
import { createContext, useEffect, useState } from "react";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
// ** Axios
import axios from "axios";

// ** Config
import { authConfig } from "src/configs/auth";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// ** Defaults
const defaultProvider = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  resetPassword: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
};
const AuthContext = createContext(defaultProvider);

// function useQuery() {
//   const { search } = useLocation();

//   return useMemo(() => new URLSearchParams(search), [search]);
// }

const stripTrailingSlash = (str) => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user);
  const [loading, setLoading] = useState(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized);
  const [search, setSearch] = useSearchParams();
  // ** Hooks

  const searchToken = search.get("token");
  if (searchToken) {
    window.localStorage.setItem(authConfig.storageTokenKeyName, searchToken);
  }
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("AuthProvider", location);
  useEffect(() => {
    const initAuth = async () => {
      const publicPages = ["/login", "/register", "/forgot-password", "/reset-password"];
      // console.log("AuthProvider location.pathname", location.pathname);
      if (publicPages.includes(stripTrailingSlash(location.pathname))) {
        setLoading(false);
        return;
      }

      const redirectURL = location.pathname + location.search;
      setIsInitialized(true);
      setLoading(true);
      const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName);
      if (storedToken) {
        await axios
          .get(authConfig.meEndpoint, {
            headers: {
              Authorization: storedToken,
            },
          })
          .then(async (response) => {
            setLoading(false);
            setUser({ ...response.data.userData });
          })
          .catch(() => {
            localStorage.removeItem("userData");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("accessToken");
            setUser(null);
            setLoading(false);

            navigate(`/login/?returnUrl=${redirectURL}`);
          });
      } else {
        setLoading(false);
        navigate(`/login/?returnUrl=${redirectURL}`);
      }
    };
    initAuth();
  }, []);

  const handleLogin = (params, errorCallback) => {
    axios
      .post(authConfig.loginEndpoint, params)
      .then(async (res) => {
        window.localStorage.setItem(authConfig.storageTokenKeyName, res.data.accessToken);
        getAcademyMe(res.data.accessToken);
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);
      });
  };

  const getAcademyMe = async (accessToken) => {
    try {
      axios
        .get(authConfig.meEndpoint, {
          headers: {
            Authorization: accessToken, //window.localStorage.getItem(authConfig.storageTokenKeyName),
          },
        })
        .then(async (response) => {
          const returnUrl = location.search.returnUrl;
          setUser({ ...response.data.userData });
          await window.localStorage.setItem("userData", JSON.stringify(response.data.userData));
          const redirectURL = returnUrl && returnUrl !== "/" ? returnUrl : "/";
          //  console.log("navigate(redirectURL)", redirectURL);
          navigate(redirectURL);
        });
    } catch (err) {
      console.log("getAcademyMe err", err);
    }
  };

  const handleForgotPassword = (params, errorCallback, successCallback) => {
    axios
      .post(authConfig.forgotPasswordEndpoint, params)
      .then(async (res) => {
        if (successCallback) successCallback(res);
      })

      .catch((err) => {
        if (errorCallback) errorCallback(err);
      });
  };

  const handleResetPassword = (params, errorCallback, successCallback) => {
    axios
      .post(authConfig.resetPasswordEndpoint, params)
      .then(async (res) => {
        if (successCallback) successCallback(res);
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);
      });
  };

  const handleChangePassword = (params, errorCallback, successCallback) => {
    axios
      .post(authConfig.changePasswordEndpoint, params)
      .then(async (res) => {
        if (successCallback) successCallback(res);
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);
      });
  };

  const handleLogout = () => {
    setUser(null);
    setIsInitialized(false);
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem(authConfig.storageTokenKeyName);
    navigate("/login");
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    forgotPassword: handleForgotPassword,
    resetPassword: handleResetPassword,
    changePassword: handleChangePassword,

    //register: handleRegister
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
