import React from "react";
const defaultThumbnail = "/images/default-course.png";
function Thumbnail({ image }) {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <img alt="" style={{ borderRadius: 8, width: "100%", maxWidth: 300 }} src={image || defaultThumbnail} />
    </div>
  );
}
export default Thumbnail;
