// ** React Imports
import { useState, useEffect } from "react";

// ** Next Import
import { useLocation } from "react-router-dom";

const WindowWrapper = ({ children }) => {
  // ** State
  const [windowReadyFlag, setWindowReadyFlag] = useState(false);
  const location = useLocation();
  useEffect(
    () => {
      if (typeof window !== "undefined") {
        setWindowReadyFlag(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );
  if (windowReadyFlag) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export default WindowWrapper;
