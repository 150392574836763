import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { MobileView } from "react-device-detect";
// ** MUI Imports
import Alert from "@mui/material/Alert";
import Spinner from "src/@core/components/spinner";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { course_get, course_update } from "src/api/courses";
import CourseViewLayout from "../../layouts/CourseViewLayout/VideoLayout";
import { useSettings } from "src/@core/hooks/useSettings";
import { accessEnded } from "src/@core/utils/course-date";
// ** Third Party Components
import toast from "react-hot-toast";

// ** Hooks
import { useAuth } from "src/hooks/useAuth";

const Course = ({ match }) => {
  const auth = useAuth();
  const { settings, saveSettings } = useSettings();
  const [isLoading, setIsLoading] = useState(true);
  const [course, setCourse] = useState({});
  const [actualChapter, setActualChapter] = useState(null);
  const [actualLesson, setActualLesson] = useState(null);
  //const [actualLessonIndex, setActualLessonIndex] = useState(0);
  const [isFirstLesson, setIsFirstLesson] = useState(true);
  const [isLastLesson, setIsLastLesson] = useState(false);
  const [lessonsArray, setLessonsArray] = useState([]);

  const [lessonsStatus, setLessonsStatus] = useState([]);
  const [errorLoadingData, setErrorLoadingData] = useState({ visible: false, message: "", code: -1 });
  const [noLessonsAvaliable, setNoLessonsAvaliable] = useState(false);
  const [videosInfos, setVideosInfos] = useState([]);
  let params = useParams();
  const navigate = useNavigate();

  const course_id = params?.course_id;
  const chapter_id = params?.chapter_id;
  const lesson_id = params?.lesson_id;

  // const course_id = courseQuery !== undefined ? courseQuery[0] : null;
  // const chapter_id = courseQuery !== undefined ? courseQuery[1] : null;
  // const lesson_id = courseQuery !== undefined ? courseQuery[2] : null;
  const authUser = auth?.user;

  useEffect(() => {
    if (course_id && authUser) loadCourse(course_id);
  }, [course_id, authUser]);

  const loadCourse = async (course_id) => {
    setErrorLoadingData({ visible: false, message: "", code: -1 });
    setIsLoading(true);

    const response = await dbGetCourse(authUser, course_id);
    // console.log("loadCourse", response.data);
    if (!response.status) {
      // console.log("loadCourse", response.data?.response?.data?.message);

      if (response.data?.response?.data?.code === 6624) {
        setErrorLoadingData({
          visible: true,
          message: "Já terminou o tempo de acesso a este curso.",
          code: 6624,
        });
      } else if (response.data?.response?.data?.code === 6623) {
        setErrorLoadingData({
          visible: true,
          message: "Não tens acesso a este curso ou o curso não existe.",
          code: 6623,
        });
      } else {
        const message =
          response.data?.response?.data?.message ||
          "Não foi possível carregar o curso. Tenta novamente mais tarde.";

        setErrorLoadingData({
          visible: true,
          message: message,
          code: -1,
        });
      }
    } else {
      const { course, details, videos_info } = response.data;

      if (details.length === 0) {
        setIsLoading(false);
        setNoLessonsAvaliable(true);
        return;
      }

      const _actualChapter = course.actual_chapter || chapter_id || details[0].chapter_id;
      const _actualLesson = course.actual_lesson || lesson_id || details[0].lessons[0].lesson_id;

      // console.log("setActiveLesson", _actualChapter, _actualLesson);
      const courseData = response.data;

      // count lessons
      //  const lessonsCount = courseData.details.reduce((acc, chapter) => acc + chapter.lessons.length, 0);
      const _lessonsArray = courseData.details.map((chapter) =>
        chapter.lessons.map((lesson) => {
          return { chapter: chapter.chapter_id, lesson: lesson.lesson_id };
        })
      );

      //Flatten lessonsArray
      const lessonsArray = [].concat.apply([], _lessonsArray);
      setLessonsArray(lessonsArray);

      setCourse(response.data);
      setVideosInfos(videos_info);
      setLessonsStatus(course.lessonStatus || []);

      dbUpdateCourse(auth.user, course_id, "global", {
        started: true,
        actual_chapter: _actualChapter,
        actual_lesson: _actualLesson,
      });
      setActiveLesson(_actualChapter, _actualLesson);
      updateFirstLastLesson(_actualChapter, _actualLesson);
    }

    setIsLoading(false);
  };

  const updateFirstLastLesson = (chapter, lesson) => {
    if (lessonsArray.length > 0) {
      //  console.log("lessonsArray", lessonsArray);
      const _isFirstLesson = lessonsArray[0].lesson === lesson;
      const _isLastLesson = lessonsArray[lessonsArray.length - 1].lesson === lesson;

      setIsFirstLesson(_isFirstLesson);
      setIsLastLesson(_isLastLesson);
    }
  };

  const setActiveLesson = (chapter, lesson) => {
    setActualChapter(parseInt(chapter));
    setActualLesson(parseInt(lesson));
    updateFirstLastLesson(chapter, lesson);
    navigate(`/courses/${course_id}/${chapter}/${lesson}`, { replace: true });
  };

  const dbGetCourse = (auth_data, course_id) => {
    return new Promise((resolve) => {
      try {
        course_get(auth_data, course_id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbUpdateCourse = (auth_data, course_id, action, payload) => {
    return new Promise((resolve) => {
      try {
        course_update(auth_data, course_id, action, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const closeError = () => {
    setErrorLoadingData({ visible: false, message: "", code: -1 });

    navigate("/home?type=error");
  };

  const lessonStatusHandler = async (chapter_id, lesson_id, completed) => {
    setLessonsStatus([...lessonsStatus, { lesson_id, completed }]);
    const response = await dbUpdateCourse(auth.user, course_id, "updateLessonStatus", {
      lesson_id,
      completed,
    });
    if (!response.status) {
      // const selectedColor = theme.palette.mode === 'dark' ? '#a35a5a' : '#ffe3e3'

      toast.error("Oops... Erro ao atualizar estado da aula...", {
        position: "top-right",
      });

      return;
    }
    setLessonsStatus(response.data.lessonStatus);
    // console.log("error", response.data);
  };

  const activeLessonHandler = (chapter, lesson_id) => {
    setActiveLesson(chapter, lesson_id);
    updateFirstLastLesson(chapter, lesson_id);
    dbUpdateCourse(auth.user, course_id, "global", { actual_chapter: chapter, actual_lesson: lesson_id });
  };

  const updateVideoCurrentTime = (chapter, lesson_id, video_id, current_time) => {
    if (!video_id || !current_time) return;
    dbUpdateCourse(auth.user, course_id, "updateVideoCurrentTime", { video_id, current_time });
  };

  const updateAudioCurrentTime = (lesson_id, current_time) => {
    if (!lesson_id || !current_time) return;
    dbUpdateCourse(auth.user, course_id, "updateAudioCurrentTime", { lesson_id, current_time });
  };

  const moveToPreviousLesson = () => {
    let counter = 0;

    course.details.forEach((chapter) => {
      chapter.lessons.forEach((lesson) => {
        counter++;
        if (lesson.lesson_id === actualLesson) {
          const nextLesson = chapter.lessons[chapter.lessons.indexOf(lesson) - 1];
          if (nextLesson) {
            // setActualLessonIndex(counter);
            activeLessonHandler(chapter.chapter_id, nextLesson.lesson_id);
          } else {
            const nextChapter = course.details[course.details.indexOf(chapter) - 1];
            if (nextChapter) {
              //  setActualLessonIndex(counter);
              activeLessonHandler(
                nextChapter.chapter_id,
                nextChapter.lessons[nextChapter.lessons.length - 1].lesson_id
              );
            }
          }
        }
      });
    });
  };
  const moveToNextLesson = () => {
    let counter = 0;

    course.details.forEach((chapter) => {
      chapter.lessons.forEach((lesson) => {
        counter++;
        if (lesson.lesson_id === actualLesson) {
          const nextLesson = chapter.lessons[chapter.lessons.indexOf(lesson) + 1];
          if (nextLesson) {
            //setActualLessonIndex(counter);
            activeLessonHandler(chapter.chapter_id, nextLesson.lesson_id);
          } else {
            const nextChapter = course.details[course.details.indexOf(chapter) + 1];
            if (nextChapter) {
              // setActualLessonIndex(counter);
              activeLessonHandler(nextChapter.chapter_id, nextChapter.lessons[0].lesson_id);
            }
          }
        }
      });
    });
  };

  return (
    <>
      {/* <MobileView>
        <Alert severity="warning">Ainda não existe suporte completo a telemóveis. Brevemente.</Alert>
      </MobileView> */}
      {!isLoading && !errorLoadingData.visible && !noLessonsAvaliable && (
        <CourseViewLayout
          // lessonsArray={lessonsArray}
          isFirstLesson={isFirstLesson}
          isLastLesson={isLastLesson}
          // actualLessonIndex={actualLessonIndex}
          moveToPreviousLesson={moveToPreviousLesson}
          moveToNextLesson={moveToNextLesson}
          updateVideoCurrentTime={updateVideoCurrentTime}
          updateAudioCurrentTime={updateAudioCurrentTime}
          settings={settings}
          saveSettings={saveSettings}
          data={course}
          videos_info={videosInfos}
          actualChapter={actualChapter}
          actualLesson={actualLesson}
          lessonsStatus={lessonsStatus}
          lessonStatusHandler={lessonStatusHandler}
          activeLessonHandler={activeLessonHandler}
        />
      )}
      {isLoading && <Spinner title="A carregar curso..." />}
      {noLessonsAvaliable && (
        <Fragment>
          <Dialog
            open={noLessonsAvaliable}
            onClose={() => navigate("/home")}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Oops!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Não existem aulas disponíveis para este curso. Em caso de dúvidas, contacta o produtor deste
                produto.
              </DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions-dense">
              <Button onClick={() => navigate("/home")}>Voltar</Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
      {errorLoadingData.visible && (
        <Fragment>
          <Dialog
            open={errorLoadingData.visible}
            onClose={closeError}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Oops!</DialogTitle>
            <DialogContent>
              {errorLoadingData.code === 6623 && <></>}
              <DialogContentText id="alert-dialog-description">{errorLoadingData.message}</DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions-dense">
              <Button onClick={closeError}>Voltar</Button>
              <Button onClick={() => loadCourse(course_id)}>Tentar Novamente</Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </>
  );
};

export default Course;
