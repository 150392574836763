import React from "react";

import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import { accessEnded } from "src/@core/utils/course-date";

function Footer({ data, loading }) {
  const buttonTitle = (title_data) => {
    if (accessEnded(data?.courses_students?.ended_at)) return <em>O acesso ao curso terminou</em>;
    if (data?.courses_students?.started === true) return "Continuar a Ver";

    return "Iniciar Curso";
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Button
        disabled={accessEnded(data?.courses_students?.ended_at)}
        href={`/courses/${data?.course_id}`}
        variant="extended"
        sx={{
          display: "block",
          p: 1,
          width: "100%",
          pt: 3,
          pb: 3,
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          textTransform: "none",
          textAlign: "center",
        }}
      >
        <Typography variant="caption" className="text-start-continue">
          {loading ? (
            <div style={{ marginLeft: "20%", marginRight: "20%" }}>
              <Skeleton animation="wave" />
            </div>
          ) : (
            <>{buttonTitle(data?.courses_students)}</>
          )}
        </Typography>
      </Button>
    </Box>
  );
}

export default Footer;
