// ** O layout para visualização de vídeos dos cursos

import React, { useState } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import ArrowCollapseLeft from "mdi-material-ui/ArrowCollapseLeft";
// ** Components Imports
import AppBarVideo from "../components/videoComponents/Header/AppBarVideo";
import Details from "../components/videoComponents/Details";
import LessonContent from "../components/videoComponents/LessonContent/LessonContent";
import TransactionInfo from "./TransactionInfo";
import Comments from "../components/Comments";
import DesktopPlaylist from "../components/videoComponents/PlayList/DesktopPlaylist";
import MobilePlaylist from "../components/videoComponents/PlayList/MobilePlaylist";
import { useAuth } from "src/hooks/useAuth";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment/moment";

function CourseViewLayout(props) {
  const {
    settings,
    saveSettings,
    data,
    videos_info,
    actualChapter,
    actualLesson,
    lessonStatusHandler,
    activeLessonHandler,
    updateVideoCurrentTime,
    updateAudioCurrentTime,
    lessonsStatus,
    moveToNextLesson,
    moveToPreviousLesson,
    isFirstLesson,
    isLastLesson,
  } = props;
  const { course, details } = data;
  const auth = useAuth();
  const isSmallDevice = window.innerWidth < 1400;

  const [tab, setTab] = useState("1"); //Guarda valor da tab atual
  const [collapsedList, setCollapsedList] = useState(false); //Guarda valor do estado da lista de reprodução
  const updateVideoCurrentTimeHandlerMiddleware = (video_id, currentTime) => {
    updateVideoCurrentTime(actualChapter, actualLesson, video_id, currentTime);
  };

  const updateAudioCurrentTimeHandlerMiddleware = (lesson_id, currentTime) => {
    updateAudioCurrentTime(actualLesson, lesson_id, currentTime);
  };

  const onShowAttachmentsClickHandler = () => {
    //console.log('onShowAttachmentsClickHandler')
    setTab("2");
  };

  const onCollapseListClickHandler = () => {
    // console.log("onCollapseListClickHandler");
    setCollapsedList(!collapsedList);
  };

  const updateLessonAsCompleted = () => {
    //console.log('updateLessonAsCompleted')
    lessonStatusHandler(actualChapter, actualLesson, true);
  };

  let activeChapter = details?.find((detail) => detail.chapter_id === actualChapter);

  if (activeChapter === undefined) activeChapter = details[0];

  let activeLesson = details
    ?.find((detail) => detail.chapter_id === actualChapter)
    ?.lessons?.find((lesson) => lesson.lesson_id === actualLesson);

  if (activeLesson === undefined) {
    activeLesson = activeChapter?.lessons[0];
    activeLessonHandler(activeChapter.chapter_id, activeLesson.lesson_id);
  }

  const isLastChapter = details[details.length - 1]?.chapter_id === actualChapter;
  const _lessons = details[details.length - 1]?.lessons;
  //const isLastLesson = _lessons[_lessons.length - 1]?.lesson_id === actualLesson;

  // console.log("isLastChapter", isLastChapter);
  // console.log("isLastLesson", isLastLesson);
  return (
    <>
      <AppBarVideo
        collapsedList={collapsedList}
        title={course?.title}
        settings={settings}
        saveSettings={saveSettings}
        whiteLogo={course?.brand_logo_white}
        darkLogo={course?.brand_logo_dark || course?.brand_logo_white}
      />

      {/*"Header" da página*/}
      {collapsedList && !isSmallDevice && (
        <div className="collapsed-button" onClick={() => onCollapseListClickHandler()}>
          {/* <Button onClick={onCollapseListClickHandler}>Expandir lista</Button> */}
          <Tooltip title="Expandir lista de reprodução" placement="left">
            <Fab
              variant="extended"
              size="small"
              sx={{ "& svg": { mr: 1 } }}
              onClick={() => onCollapseListClickHandler()}
            >
              &nbsp;
              <ArrowCollapseLeft />
              &nbsp;&nbsp;&nbsp;&nbsp;
            </Fab>
          </Tooltip>
        </div>
      )}
      <Box>
        <div className={`frame-left ${collapsedList || isSmallDevice ? "collapsed-list" : ""}`}>
          <TransactionInfo course={course} />
          <LessonContent
            activeChapter={activeChapter}
            courseAddedAt={course?.added_at}
            courseEndDate={course?.ended_at}
            isLastChapter={isLastChapter}
            isLastLesson={isLastLesson}
            moveToNextLesson={moveToNextLesson}
            updateLessonAsCompleted={updateLessonAsCompleted}
            course={course}
            videos_info={videos_info}
            details={activeLesson}
            lessonsStatus={lessonsStatus}
            updateVideoCurrentTimeHandler={updateVideoCurrentTimeHandlerMiddleware}
            updateAudioCurrentTimeHandler={updateAudioCurrentTimeHandlerMiddleware}
          />
          <Details
            authData={auth?.user}
            details={activeLesson}
            tab={tab}
            setTab={setTab}
            activeChapter={activeChapter}
            courseAddedAt={course?.added_at}
          />
          {/* Comentários */}
          {course?.allow_comments && (
            <Comments
              activeChapter={activeChapter}
              courseAddedAt={course?.added_at}
              isSmallDevice={isSmallDevice}
              courseId={course?.course_id}
              userCanReply={course?.user_can_reply}
              chapterId={activeChapter.chapter_id}
              lessonId={activeLesson.lesson_id}
              auth={auth}
            />
          )}
        </div>
      </Box>
      {!isSmallDevice && (
        <>
          {!collapsedList && (
            <div className={`frame-right ${collapsedList ? "collapsed-list" : ""}`}>
              <DesktopPlaylist
                isFirstLesson={isFirstLesson}
                isLastLesson={isLastLesson}
                onCollapseListClick={onCollapseListClickHandler}
                courseAddedAt={course?.added_at}
                courseEndDate={course?.ended_at}
                lessonsStatus={lessonsStatus}
                videos_info={videos_info}
                actualChapter={actualChapter}
                actualLesson={actualLesson}
                details={details}
                lessonStatusHandler={lessonStatusHandler}
                showLessonNumbers={course?.show_lesson_numbers}
                activeLessonHandler={(chapter, lesson_id) => {
                  activeLessonHandler(chapter, lesson_id);
                  if (isSmallDevice) onCollapseListClickHandler();
                }}
                onShowAttachmentsClick={onShowAttachmentsClickHandler}
              />
            </div>
          )}
        </>
      )}
      {isSmallDevice && (
        <MobilePlaylist
          isFirstLesson={isFirstLesson}
          isLastLesson={isLastLesson}
          courseAddedAt={course?.added_at}
          courseEndDate={course?.ended_at}
          lessonsStatus={lessonsStatus}
          videos_info={videos_info}
          actualChapter={actualChapter}
          actualLesson={actualLesson}
          details={details}
          moveToPreviousLesson={moveToPreviousLesson}
          moveToNextLesson={moveToNextLesson}
          lessonStatusHandler={lessonStatusHandler}
          showLessonNumbers={course?.show_lesson_numbers}
          activeLessonHandler={(chapter, lesson_id) => {
            activeLessonHandler(chapter, lesson_id);
            if (isSmallDevice) onCollapseListClickHandler();
          }}
          onShowAttachmentsClick={onShowAttachmentsClickHandler}
        />
      )}
    </>
  );
}

export default CourseViewLayout;
