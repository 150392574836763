import Typo from "src/@core/components/typo";
import PlayCircle from "mdi-material-ui/PlayCircle";
import TextLong from "mdi-material-ui/TextLong";
import Attachment from "mdi-material-ui/Attachment";
import VolumeHigh from "mdi-material-ui/VolumeHigh";
import ClockOutline from "mdi-material-ui/ClockOutline";
import moment from "moment";
import Chip from "@mui/material/Chip";

const getVideosInformation = (videos_info, video_id, field) => {
  try {
    if (!videos_info) return null;

    const item = videos_info.find((item) => item.video_id === video_id);

    return item[field];
  } catch (e) {
    //console.log("getVideosInformation", e);
    return null;
  }
};

export const AttachmentsInfo = ({ lesson, onClick }) => {
  if (lesson.files_content === undefined || lesson.files_content === null) return undefined;
  if (lesson.files_content.length === 0) return undefined;

  return (
    <div style={{ marginTop: 4 }}>
      <Chip
        onClick={onClick}
        className="attachments-info-chip"
        size="small"
        label="Materiais"
        color="primary"
        rounded
        icon={<Attachment sx={{ fontSize: "0.7rem" }} />}
      />
    </div>
  );
};

export const TextLessonInfo = () => {
  return (
    <div style={{ marginTop: 4 }}>
      <Typo fontSize="0.6rem" align={"left"}>
        <div style={{ display: "flex", alignItems: "start" }}>
          <TextLong sx={{ fontSize: "0.8rem", marginRight: 1 }} />
        </div>
      </Typo>
    </div>
  );
};

export const AudioLessonInfo = () => {
  return (
    <div style={{ marginTop: 4 }}>
      <Typo fontSize="0.6rem" align={"left"}>
        <div style={{ display: "flex", alignItems: "start" }}>
          <VolumeHigh sx={{ fontSize: "0.8rem", marginRight: 1 }} />
        </div>
      </Typo>
    </div>
  );
};

const LibraryVideoTime = ({ videos_info, video_source }) => {
  if (!videos_info) return <></>;
  if (!video_source) return <></>;

  const videoSeconds = getVideosInformation(videos_info, video_source, "maxDurationSeconds") * 1000;
  if (!videoSeconds) return <></>;

  let timeFormat = "mm[m] ss[s]";
  if (videoSeconds > 3600000) {
    timeFormat = "hh[h] mm[m] ss[s]";
  }

  return (
    <>
      <ClockOutline sx={{ fontSize: "0.7rem", marginRight: 1 }} />
      {moment.utc(videoSeconds).format(timeFormat)}
    </>
  );
};

const VideoTime = ({ video_length }) => {
  if (!video_length) return <></>;

  const videoSeconds = video_length * 1000;
  //  console.log("VideoTime", videoSeconds);
  let timeFormat = "mm[m] ss[s]";
  if (videoSeconds > 3600000) {
    timeFormat = "hh[h] mm[m] ss[s]";
  }

  return <>{moment.utc(videoSeconds).format(timeFormat)}</>;
};

export const VideoLessonInfo = () => {
  return (
    <div style={{ marginTop: 4 }}>
      <Typo fontSize="0.6rem" align={"left"}>
        <div style={{ display: "flex", alignItems: "start" }}>
          <PlayCircle sx={{ fontSize: "0.8rem", marginRight: 1 }} />
        </div>
      </Typo>
    </div>
  );
};

export const LessonLength = ({ videos_info, lesson }) => {
  if (lesson?.lesson_type === "video" && lesson?.video_origin === "library") {
    return (
      <div className="lesson-length" style={{ marginTop: 4 }}>
        <LibraryVideoTime videos_info={videos_info} video_source={lesson?.video_source} />
      </div>
    );
  }

  if (
    lesson?.lesson_type === "video" &&
    lesson?.video_origin === "external" &&
    lesson?.video_length !== undefined
  ) {
    return (
      <div className="lesson-length" style={{ marginTop: 4 }}>
        <ClockOutline sx={{ fontSize: "0.7rem", marginRight: 1 }} />
        <VideoTime video_length={lesson?.video_length} />
      </div>
    );
  }

  if (lesson?.lesson_type === "audio" && lesson?.audio_source?.duration !== undefined) {
    return (
      <div className="lesson-length" style={{ marginTop: 4 }}>
        <ClockOutline sx={{ fontSize: "0.7rem", marginRight: 1 }} />
        {moment.utc(lesson?.audio_source?.duration * 1000).format("mm[m] ss[s]")}
      </div>
    );
  }
};

export const LessonInfo = ({ lesson }) => {
  if (lesson?.lesson_type === "text") return <TextLessonInfo />;
  if (lesson?.lesson_type === "audio") return <AudioLessonInfo />;
  if (lesson?.lesson_type === "video") return <VideoLessonInfo />;
};

export const isChapterAvailable = (chapter, courseAddedAt) => {
  if (!chapter) return false;
  if (chapter.status_code === 0) return false;
  if (chapter.status_code === 1) return true;

  // Dripping
  if (chapter.status_code === 2 && chapter.drip_days !== undefined) {
    const avaliableDate = moment.utc(courseAddedAt).add(chapter.drip_days, "days");

    if (moment.utc().isAfter(avaliableDate)) return true;
  }

  // Avaliable On
  if (chapter.status_code === 3 && chapter.avaliable_on !== undefined) {
    if (moment.utc().isAfter(chapter.avaliable_on)) return true;
  }

  return false;
};

// export const isChapterAvailable__old = (dripDays, courseAddedAt, status_code) => {
//   if (status_code === 1) return true;
//   const avaliableDate = moment.utc(courseAddedAt).add(dripDays, "days");
//   if (moment.utc().isAfter(avaliableDate)) return true;
//   return false;
// };

export const ChapterAvaliableIn = ({ chapter, courseAddedAt, courseEndDate }) => {
  if (!chapter) return <></>;
  if (!chapter?.status_code) return <></>;

  let fromNow = undefined;
  let avaliable_on = undefined;
  let endDate = undefined;

  // Dripping
  if (chapter.status_code === 2 && chapter.drip_days !== undefined) {
    const avaliableDate = moment.utc(courseAddedAt).add(chapter.drip_days, "days");
    if (moment.utc().isAfter(avaliableDate)) return <></>;

    fromNow = moment.utc(avaliableDate).fromNow(true);
    avaliable_on = moment.utc(avaliableDate);
  }

  // Avaliable On
  if (chapter.status_code === 3 && chapter.avaliable_on !== undefined) {
    if (moment.utc().isAfter(chapter.avaliable_on)) return <></>;

    fromNow = moment.utc(chapter.avaliable_on).fromNow(true);
    avaliable_on = moment.utc(chapter.avaliable_on);
  }

  if (courseEndDate !== undefined && courseEndDate !== null) {
    // Check if and date is before avaliable_on
    endDate = moment.utc(courseEndDate);
    if (endDate.isBefore(avaliable_on)) {
      return (
        <div className="soon-chip-container">
          <Chip
            className="soon-chip"
            label={`Disponível até ${moment.utc(courseEndDate).local().format("DD-MM-YYYY")}`}
            skin="light"
            size="small"
            color="warning"
          />
        </div>
      );
    }
  }

  if (
    chapter.status_code === 0 ||
    (chapter.status_code === 1 && courseEndDate !== undefined && courseEndDate !== null)
  ) {
    return (
      <div style={{ margin: 0, padding: 0, lineHeight: "10px" }}>
        <small style={{ fontSize: "10px" }}>
          Disponível até {moment.utc(courseEndDate).local().format("DD-MM-YYYY")}
        </small>
      </div>
    );
  }
  if (fromNow !== undefined) {
    return (
      <div className="soon-chip-container">
        <Chip
          className="soon-chip"
          label={`Disponível dentro de ${fromNow}`}
          skin="light"
          size="small"
          color="info"
        />
      </div>
    );
  }
  return <></>;
};
