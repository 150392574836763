import React from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function Description({ description }) {
  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h6" sx={{ fontSize: "1rem" }}>
        Descrição
      </Typography>

      <Box>
        <Typography>
          <div className="panel-description" dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>

        {/* <Typography variant='body1' paragraph align='left' sx={{ fontSize: '0.9rem' }}>
          {description}
        </Typography> */}
      </Box>
    </Box>
  );
}

export default Description;
