// ** React Imports
import { Fragment, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Spinner from "src/@core/components/spinner";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CardHeader from "@mui/material/CardHeader";
import AlertTitle from "@mui/material/AlertTitle";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import DialogTitle from "@mui/material/DialogTitle";
import OutlinedInput from "@mui/material/OutlinedInput";
import DialogContent from "@mui/material/DialogContent";
import InputAdornment from "@mui/material/InputAdornment";

import toast from "react-hot-toast";

import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";

// ** Icons Imports
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";

import { useAuth } from "src/hooks/useAuth";

const strengthChecker = (PasswordParameter, setPasswordStrength) => {
  if (PasswordParameter.length === 0) return false;
  // let strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
  // let mediumPassword = new RegExp(
  //   "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
  // );

  let strongPassword = new RegExp("^.{6,16}$");
  let mediumPassword = new RegExp("^.{4,16}$");

  if (strongPassword.test(PasswordParameter)) {
    if (setPasswordStrength) {
      setPasswordStrength({ visible: true, color: "success", text: "Palavra passe forte!" });
    }
    return true;
  } else if (mediumPassword.test(PasswordParameter)) {
    if (setPasswordStrength) {
      setPasswordStrength({
        visible: true,
        color: "warning",
        text: "Palavra passe de média segurança. Podia ser melhor...",
      });
    }
    return true;
  } else {
    setPasswordStrength({
      visible: true,
      color: "error",
      text: "Palavra passe fraca. Tem de ser mais complexa...",
    });
    return false;
  }
};

function SecurityTab() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [defaultValues, setDefaultValues] = useState({ mobile: null });
  const [mobileNumber, setMobileNumber] = useState(defaultValues.mobile);
  const [openEditMobileNumber, setOpenEditMobileNumber] = useState(false);
  const [noMatchingPasswordModal, setNoMatchingPasswordModal] = useState({ visible: false, message: "" });
  const [passwordStrength, setPasswordStrength] = useState({ visible: false, color: "red", text: "Weak" });
  const [showError, setShowError] = useState(false);
  const [errorDetails, setErrorDetails] = useState(null);
  const [reLogin, setReLogin] = useState(false);
  const [values, setValues] = useState({
    newPassword: "",
    showNewPassword: false,
    confirmNewPassword: "",
    showConfirmNewPassword: false,
  });

  // Handle Password
  const handleNewPasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (event?.target?.value?.length > 0) {
      const checkpwd = strengthChecker(event?.target?.value, setPasswordStrength);
      if (!checkpwd) {
        return;
      }
    } else {
      setPasswordStrength({
        visible: false,
        color: "",
        text: "",
      });
    }
  };

  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  // Handle Confirm Password
  const handleConfirmNewPasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowConfirmNewPassword = () => {
    setValues({ ...values, showConfirmNewPassword: !values.showConfirmNewPassword });
  };

  const handleMouseDownConfirmNewPassword = (event) => {
    event.preventDefault();
  };

  // Handle edit mobile number dialog
  const handleEditMobileNumberClickOpen = () => setOpenEditMobileNumber(true);
  const handleEditMobileNumberClose = () => setOpenEditMobileNumber(false);

  // Handle button click inside the dialog
  const handleCancelClick = () => {
    //setMobileNumber(defaultValues.mobile);
    // handleEditMobileNumberClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (values.newPassword !== values.confirmNewPassword) {
      setNoMatchingPasswordModal({ visible: true, message: "As senhas não coincidem" });
      return;
    }

    const checkpwd = strengthChecker(values.newPassword, setPasswordStrength);

    if (checkpwd === false) {
      return;
    }

    onSubmit(values);
    // setDefaultValues({ ...defaultValues, mobile: mobileNumber });
    //  handleEditMobileNumberClose();
  };

  const onSubmit = (data) => {
    setShowError(false);
    setErrorDetails(null);

    auth.changePassword(
      { ...data, token: auth.user.token, email: auth.user.email },
      (a) => {
        setShowError(true);
        setErrorDetails(a?.response?.data?.reason);
      },
      () => {
        toast.success("Palavra passe alterada com sucesso! Necessário efetuar login novamente.");
        setReLogin(true);
        setTimeout(() => {
          navigate("/login");
        }, 6000);
      }
    );
  };

  return (
    <Fragment>
      {reLogin && <Spinner title="É necessário iniciar novamente a sessão com a tua nova senha..." />}
      <Card sx={{ mb: 6 }}>
        <CardHeader title="Alterar Senha" titleTypographyProps={{ variant: "h6" }} />
        <CardContent>
          {/* <Alert icon={false} severity="warning" sx={{ mb: 4 }}>
            <AlertTitle sx={{ mb: (theme) => `${theme.spacing(1)} !important` }}>
              Assegura-te que estes requisitos são cumpridos
            </AlertTitle>
            Mínimo 4 caracteres de comprimento, maiúsculas e símbolo
          </Alert> */}

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2.5}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="user-view-security-new-password">Nova Senha</InputLabel>
                  <OutlinedInput
                    label="Nova Senha"
                    value={values.newPassword}
                    id="user-view-security-new-password"
                    onChange={handleNewPasswordChange("newPassword")}
                    type={values.showNewPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClickShowNewPassword}
                          aria-label="toggle password visibility"
                          onMouseDown={handleMouseDownNewPassword}
                        >
                          {values.showNewPassword ? <EyeOutline /> : <EyeOffOutline />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth size="small">
                  <InputLabel htmlFor="user-view-security-confirm-new-password">Confirmar Nova Senha</InputLabel>
                  <OutlinedInput
                    label="Confirmar Nova Senha"
                    value={values.confirmNewPassword}
                    id="user-view-security-confirm-new-password"
                    type={values.showConfirmNewPassword ? "text" : "password"}
                    onChange={handleConfirmNewPasswordChange("confirmNewPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmNewPassword}
                          onMouseDown={handleMouseDownConfirmNewPassword}
                        >
                          {values.showConfirmNewPassword ? <EyeOutline /> : <EyeOffOutline />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ mt: 1.5 }}>
                {passwordStrength.visible && (
                  <Alert severity={passwordStrength.color}>{passwordStrength.text}</Alert>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mt: 1.5 }}>
                <Button type="submit" variant="contained">
                  Alterar Senha
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      {/* 
      <Card sx={{ mb: 6 }}>
        <CardHeader
          title='Two-step verification'
          titleTypographyProps={{ variant: 'h6' }}
          subheaderTypographyProps={{ variant: 'body2' }}
          subheader='Keep your account secure with authentication step.'
        />
        <CardContent>
          <Typography variant='body2' sx={{ mb: 2, fontWeight: 600, color: 'text.primary' }}>
            SMS
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='body1' sx={{ color: 'action.active' }}>
              {mobileNumber}
            </Typography>
            <Box>
              <IconButton aria-label='edit' sx={{ color: 'text.secondary' }} onClick={handleEditMobileNumberClickOpen}>
                <PencilOutline />
              </IconButton>
              <IconButton aria-label='delete' sx={{ color: 'text.secondary' }}>
                <DeleteOutline />
              </IconButton>
            </Box>
          </Box>

          <Divider sx={{ mb: 4 }} />

          <Typography variant='body2'>
            Two-factor authentication adds an additional layer of security to your account by requiring more than just a
            password to log in.{' '}
            <Link href='/' onClick={e => e.preventDefault()}>
              Learn more
            </Link>
            .
          </Typography>
        </CardContent>

        <Dialog
          open={openEditMobileNumber}
          onClose={handleCancelClick}
          aria-labelledby='user-view-security-edit-mobile-number'
          sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: 650, p: [2, 10] } }}
          aria-describedby='user-view-security-edit-mobile-number-description'
        >
          <DialogTitle
            id='user-view-security-edit-mobile-number'
            sx={{ textAlign: 'center', fontSize: '1.5rem !important' }}
          >
            Enable One Time Password
          </DialogTitle>

          <DialogContent>
            <Typography variant='h6'>Verify Your Mobile Number for SMS</Typography>
            <Typography variant='body2' sx={{ mt: 2, mb: 5 }}>
              Enter your mobile phone number with country code and we will send you a verification code.
            </Typography>
            <form onSubmit={e => e.preventDefault()}>
              <TextField
                fullWidth
                value={mobileNumber}
                label='Mobile number with country code'
                onChange={e => setMobileNumber(e.target.value)}
              />
              <Box sx={{ mt: 6.5, display: 'flex', justifyContent: 'flex-end' }}>
                <Button type='reset' color='secondary' variant='outlined' onClick={handleCancelClick}>
                  Cancel
                </Button>
                <Button type='submit' sx={{ ml: 3 }} variant='contained' onClick={handleSubmitClick}>
                  Send
                </Button>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </Card> */}

      {/* 
      <Card>
        <CardHeader title="Dispositivos Recentes" titleTypographyProps={{ variant: "h6" }} />

        <Divider sx={{ m: 0 }} />

        <TableContainer>
          <Table sx={{ minWidth: 500 }}>
            <TableHead
              sx={{
                backgroundColor: (theme) => (theme.palette.mode === "light" ? "grey.50" : "background.default"),
              }}
            >
              <TableRow>
                <TableCell sx={{ py: 3 }}>Navegador</TableCell>
                <TableCell sx={{ py: 3 }}>Dispositivo</TableCell>
                <TableCell sx={{ py: 3 }}>Localização</TableCell>
                <TableCell sx={{ py: 3 }}>Actividade recente</TableCell>
              </TableRow>
            </TableHead>

            <TableBody></TableBody>
          </Table>
        </TableContainer>
      </Card> */}
      {noMatchingPasswordModal.visible && (
        <Fragment>
          <Dialog
            open={noMatchingPasswordModal.visible}
            onClose={() => setNoMatchingPasswordModal({ visible: false, message: "" })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Oops!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{noMatchingPasswordModal.message}</DialogContentText>
            </DialogContent>
            <DialogActions className="dialog-actions-dense">
              <Button onClick={() => setNoMatchingPasswordModal({ visible: false, message: "" })}>OK</Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  );
}

export default SecurityTab;
