import ThemeComponent from "src/@core/theme/ThemeComponent";
import { AuthProvider } from "src/context/AuthContext";
import AuthGuard from "src/@core/components/auth/AuthGuard";
import WindowWrapper from "src/@core/components/window-wrapper";

import { SettingsConsumer, SettingsProvider } from "src/@core/context/settingsContext";

import { Route, Routes, Navigate } from "react-router-dom";

// ** Styled Components
import ReactHotToast from "src/@core/styles/libs/react-hot-toast";
// ** Third Party Import
import { Toaster } from "react-hot-toast";

//import Gleap from "gleap";

import Home from "src/pages/home";
import LoginPage from "./pages/login";
import ForgotPassword from "./pages/forgot-password";
import Course from "./pages/courses";
import Messages from "./pages/messages";
import Wishlist from "./pages/wishlist";
import Profile from "./pages/profile";
import ResetPassword from "./pages/reset-password";

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css";

// ** Global css styles
import "./assets/css/globals.css";
import "./assets/css/audio-player.css";
import "./assets/css/video-player.css";

function App(props) {
  // Variables

  console.log(window.location);

  return (
    <AuthProvider>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => {
            const pathname = window.location.pathname;
            if (pathname === "/login" || pathname === "/forgot-password" || pathname === "/reset-password") {
              if (settings) settings.mode = "light";
            }

            return (
              <>
                <ThemeComponent settings={settings}>
                  <WindowWrapper>
                    <AuthGuard>
                      <Routes>
                        <Route exact path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route exact path="/login" element={<LoginPage />} />
                        <Route exact path="/" element={<Navigate to="/home" />} />
                        <Route exact path="/home" element={<Home />} />
                        <Route exact path="/profile" element={<Profile />} />
                        <Route exact path="/messages" element={<Messages />} />
                        <Route exact path="/wishlist" element={<Wishlist />} />
                        <Route exact path="/courses/:course_id" element={<Course />} />
                        <Route exact path="/courses/:course_id/:chapter_id/:lesson_id" element={<Course />} />
                      </Routes>
                    </AuthGuard>
                  </WindowWrapper>
                  <ReactHotToast>
                    <Toaster position={settings.toastPosition} toastOptions={{ className: "react-hot-toast" }} />
                  </ReactHotToast>
                </ThemeComponent>
              </>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </AuthProvider>
  );
}

export default App;
