import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function Title({ title }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
      <Typography variant='h6' noWrap sx={{ display: 'block' }}>
        {title}
      </Typography>
    </Box>
  )
}

export default Title
