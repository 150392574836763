import React, { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const ModalDeleteComment = ({ deleteCommentModal, setDeleteCommentModal, handleCommentDelete }) => {
  return (
    <Fragment>
      <Dialog
        open={deleteCommentModal.visible}
        onClose={() => setDeleteCommentModal({ visible: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Apagar comentário</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Tens a certeza que queres apagar o teu comentário?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="dialog-actions-dense">
          <Button onClick={() => setDeleteCommentModal({ visible: false })}>Não</Button>
          <Button onClick={() => handleCommentDelete(deleteCommentModal.id)}>Sim, confirmo!</Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ModalDeleteComment;
