import React, { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Lessons from "./Lessons";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import { useTheme } from "@mui/material/styles";
import moment from "moment/moment";
import { locale } from "src/lang/locale/moment_pt_PT";
import ChevronDown from "mdi-material-ui/ChevronDown";
import ArrowCollapseRight from "mdi-material-ui/ArrowCollapseRight";
import Tooltip from "@mui/material/Tooltip";
import ChapterProgress from "./ChapterProgress";
import { isChapterAvailable, ChapterAvaliableIn } from "./helper";

moment.locale("pt", locale);

function DesktopPlayList({
  videos_info,
  details,
  actualChapter,
  actualLesson,
  lessonStatusHandler,
  activeLessonHandler,
  onShowAttachmentsClick,
  lessonsStatus,
  courseAddedAt,
  courseEndDate,
  showLessonNumbers,
  onCollapseListClick,
}) {
  // ** Hook
  const theme = useTheme();

  const marginOffset = 76;
  const marginTopOffset = 76;
  const [offset, setOffset] = useState(0);
  const [expanded, setExpanded] = useState({}); //`panel${actualChapter}`

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);

    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    const r = { ...expanded, [`panel${actualChapter}`]: true };
    setExpanded(r);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const r = { ...expanded, [`panel${actualChapter}`]: true };
    setExpanded(r);
  }, [actualChapter]);

  const offsetY = Math.abs(marginOffset - offset) < 0 ? 0 : Math.abs(marginOffset - offset);

  const handleChange = (panel) => (event, isExpanded) => {
    const _panel = isExpanded ? true : false;
    const r = { ...expanded, [panel]: _panel };
    setExpanded(r);
  };

  // Styled component for Accordion component
  const Accordion = styled(MuiAccordion)(({ theme }) => ({
    boxShadow: "none !important",
    // border: `1px solid ${theme.palette.divider}`,
    borderRadius: "0px",
    "&:not(:last-of-type)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: "2px",
    },
    "&:first-of-type": {
      "& .MuiButtonBase-root": {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
      },
    },
    "&:last-of-type": {
      "& .MuiAccordionSummary-root:not(.Mui-expanded)": {
        borderBottomLeftRadius: "0px",
        borderBottomRightRadius: "0px",
      },
    },
  }));

  const selectedColor = theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black;

  return (
    <Card
      className="playlist-container"
      style={{
        top: offset < marginOffset ? offsetY : 0,
        height: "100%",
      }}
    >
      <Box sx={{ paddingLeft: 8 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tooltip title="Esconder lista de conteúdos" placement="top">
            <Fab
              color="secondary"
              aria-label="add"
              size="small"
              style={{ width: "30px", height: "30px", lineHeight: "25px", minHeight: "30px" }}
              onClick={onCollapseListClick}
            >
              <ArrowCollapseRight style={{ width: ".9rem", height: ".9rem" }} />
            </Fab>
          </Tooltip>
          <h4 style={{ marginLeft: 10 }}>Conteúdo do curso</h4>
        </div>
      </Box>
      <div
        className="scrollbar"
        style={{
          height: `calc(100% - ${offset < marginOffset ? offsetY + marginTopOffset : marginTopOffset}px)`,
          overflowY: "scroll",
          display: "flex",
          flexFlow: "column",
        }}
      >
        {details?.map((e, i) => {
          const lessonsCounter = e?.lessons?.length;

          const chapterLessonsStatus = lessonsStatus.filter((el) =>
            e.lessons.find((lesson) => lesson.lesson_id === el.lesson_id)
          );

          let completedLessons = parseInt(chapterLessonsStatus.filter((el) => el.completed === true)?.length || 0);
          let completedPercentage = parseInt((completedLessons / lessonsCounter) * 100);
          if (completedPercentage > 100) completedPercentage = 100;
          if (completedPercentage < 0) completedPercentage = 0;
          return (
            <Accordion
              className="side-bar-accordion"
              key={e.chapter_id}
              sx={{ p: 2 }}
              expanded={expanded[`panel${e.chapter_id}`] || false}
              onChange={handleChange(`panel${e.chapter_id}`)}
            >
              <AccordionSummary
                // style={{
                //   backgroundColor: e.chapter_id === actualChapter ? hexToRGBA(selectedColor, 0.06) : "",
                // }}
                className={`side-bar-accordion-summary ${
                  e.chapter_id === actualChapter ? "side-bar-accordion-active" : ""
                }`}
                id={`panel-header-${e.chapter_id}`}
                aria-controls={`panel-content-${e.chapter_id}`}
                expandIcon={<ChevronDown />}
              >
                <div
                  className={`side-bar-accordion-summary-container ${
                    e.chapter_id === actualChapter ? "active" : ""
                  }`}
                >
                  <Typography variant="subtitle2" sx={{ mb: 1, fontSize: 16 }}>
                    <div className="chapter-container">
                      <div className="chapter-image-title">
                        {e.image !== null && e.image !== "" && e.image !== undefined && (
                          <img className="chapter-image" src={e.image} alt={e.title} />
                        )}
                        <div>
                          {e.title}
                          {isChapterAvailable(e, courseAddedAt) && (
                            <ChapterProgress completedPercentage={completedPercentage} />
                          )}
                          <ChapterAvaliableIn
                            chapter={e}
                            courseAddedAt={courseAddedAt}
                            courseEndDate={courseEndDate}
                          />
                        </div>
                      </div>
                    </div>
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className="side-bar-accordion-details">
                <div className="side-bar-accordion-details-container">
                  <Lessons
                    isChapterAvailable={isChapterAvailable(e, courseAddedAt)}
                    lessonsStatus={lessonsStatus}
                    videos_info={videos_info}
                    chapter={e?.chapter_id}
                    lessons={e?.lessons}
                    actualChapter={actualChapter}
                    actualLesson={actualLesson}
                    lessonStatusHandler={lessonStatusHandler}
                    activeLessonHandler={activeLessonHandler}
                    showLessonNumbers={showLessonNumbers}
                    onShowAttachmentsClick={onShowAttachmentsClick}
                  />
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </Card>
  );
}

export default DesktopPlayList;
