// ** Zona para inserir a descrição do vídeo
import React from "react";

import OpenInNew from "mdi-material-ui/OpenInNew";
import CloudDownloadOutline from "mdi-material-ui/CloudDownloadOutline";
import MuiLink from "@mui/material/Link";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { file_get } from "../../../../api/product-download";
// ** Custom Components
import CustomChip from "src/@core/components/mui/chip";
import { Button } from "@mui/material";

const urlElipsis = (url, isMobile) => {
  try {
    const maxLen = isMobile ? 25 : 50;
    if (url.length > maxLen) {
      return "..." + url.substr(url.length - maxLen);
    }
    return url;
  } catch (error) {
    return url;
  }
};

function Content({ content, isMobile, authData }) {
  if (content === undefined || content === null || content === "" || content?.length === 0) return null;

  const requestDownloadUrl = async (file, justUrl = true) => {
    const fileResponse = await dbGetLessonFile(authData, file);

    if (fileResponse.status === true && justUrl === true) {
      return fileResponse.data;
    }

    if (fileResponse.status === true && justUrl === false) {
      
      const url = window.URL.createObjectURL(new Blob([fileResponse.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file);
      document.body.appendChild(link);
      link.click();
    }
  };

  const dbGetLessonFile = (auth, file) => {
    return new Promise((resolve) => {
      try {
        file_get(auth, file)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  return (
    <>
      <Box sx={{ p: 4 }}>
        <>
          {content.map((item, index) => {
            const fileExtension = item?.filename?.split(".").pop();
            return (
              <Box
                key={item.key}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    display: "flex",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <CustomChip
                      size="small"
                      label={fileExtension.toUpperCase() || "Desconhecido"}
                      color="info"
                      sx={{ height: 20, fontSize: "0.75rem", fontWeight: 500, mr: 2 }}
                    />
                    <MuiLink href={item.url} target="_blank">
                      <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 600, color: "text.primary" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {urlElipsis(item.name, isMobile)} <OpenInNew fontSize="small" sx={{ ml: 2 }} />
                        </div>
                      </Typography>
                    </MuiLink>
                  </div>

                  {isMobile !== true && (
                    <Button color="primary" variant="contained" onClick={() => requestDownloadUrl(item.url, false)}>
                      Download <CloudDownloadOutline fontSize="small" sx={{ ml: 2 }} />
                    </Button>
                  )}
                  {isMobile === true && (
                    <Button
                      color="primary"
                      variant="contained"
                      sx={{ ml: 2 }}
                      onClick={() => requestDownloadUrl(item.url)}
                    >
                      <CloudDownloadOutline fontSize="small" />
                    </Button>
                  )}
                </Box>
              </Box>
            );
          })}
        </>
      </Box>
      {/* <PDFDrawer visible={pdfDrawer.visible} data={pdfDrawer.data} close={() => setPdfDrawer({ visible: false })} /> */}
    </>
  );
}

export default Content;
