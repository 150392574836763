import React from 'react'
import Box from '@mui/material/Box'

const SalesParkLogo = () => {
  return (
    <Box sx={{ mb: 8 }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src='/images/logos/logo-horizontal.png' alt='SalesPark' style={{ width: '100%', maxWidth: '200px' }} />
      </div>
    </Box>
  )
}

export default SalesParkLogo
