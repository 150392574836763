import React, { useRef, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
//import ReactAudioPlayer from 'react-audio-player'
import AudioPlayer from "react-h5-audio-player";
const defaultLessonImage = "/images/default-lesson.png";

const AudioLesson = ({ details, updateAudioCurrentTimeHandler, progressHandler }) => {
  const [url, setUrl] = useState(details?.audio_source?.url);

  const theme = useTheme();
  let playerRef = useRef();

 

  useEffect(() => {
    if (details?.audio_source?.url !== undefined) setUrl(details?.audio_source?.url);
  }, [details.audio_source]);

  //const backgroundColor = theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.common.black

  const playerStyles = {
    backgroundColor: theme.palette.background.default,
    width: "100%",
  };

  const AudioBox = styled("div")(({ theme }) => ({
    "& .rhap_container": {
      backgroundColor: `${theme.palette.background.default} !important`,
    },
    "& .rhap_main-controls-button": {
      color: `${theme.palette.text.primary} !important`,
    },

    "& .rhap_play-pause-button": {
      color: `${theme.palette.primary.main} !important`,
    },
    "& .rhap_progress-indicator": {
      background: `${theme.palette.primary.main} !important`,
    },

    "& .rhap_volume-indicator": {
      background: `${theme.palette.primary.main} !important`,
    },
    "& .rhap_time": {
      color: `${theme.palette.text.primary} !important`,
    },
  }));

  const catchTimeUpdate = (progress) => {
    const player = playerRef?.current;
    if (player) {
      const currentTime = parseInt(player.audio.current.currentTime);
      const duration = parseInt(player.audio.current.duration);
      progressHandler(currentTime, duration, false, 0);

      // if (Math.abs(currentTime - lastTimeUpdated) > 5) {
      //   setLastTimeUpdated(currentTime)
      updateAudioCurrentTimeHandler(duration, currentTime);

      // }
    }
  };

  return (
    <div className="lesson-audio-wrapper" style={{ borderRadius: 0, height: "100%", position: "inherit" }}>
      <div className="lesson-audio-container" id="lesson-audio-container">
        <div
          className="cover"
          style={{
            backgroundImage: `url("${details?.image || defaultLessonImage}")`,
          }}
        ></div>
      </div>
      <div className="audio-player-wrapper" id="lesson-audio-container">
        <AudioBox>
          <AudioPlayer
            ref={playerRef}
            style={playerStyles}
            src={url}
            autoPlay={false}
            controls
            progressUpdateInterval={500}
            listenInterval={5000}
            onEnded={(progress) => catchTimeUpdate(progress)}
          />
        </AudioBox>
      </div>
    </div>
  );
};

export default AudioLesson;
