// ** MUI Imports
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

// ** Utils Import
import ShowUserInfo from './ShowUserInfo'
import EditUserInfo from '../EditPrompt/EditUserInfo'


function ProfileView() {

    return (
        <Grid container spacing={6}>
            <Grid item xs={12}>
                <Card>
                    <ShowUserInfo/>

                    <EditUserInfo/>
                                    
                </Card> 
            </Grid>

        </Grid>
    )
}

export default ProfileView