import React from "react";

// ** MUI Imports
import Typography from "@mui/material/Typography";

const Typo = ({ children, align = "left", fontSize = "0.8rem", append }) => {
  return (
    <>
      {append !== undefined ? (
        <>
          <div>
            <div
              style={{
                fontSize: fontSize,
                display: "-webkit-box",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2,
                alignText: align,
              }}
            >
              {children}
            </div>
          </div>
          <div>{append}</div>
        </>
      ) : (
        <div
          style={{
            fontSize: fontSize,
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            alignText: align,
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};
//   return (
//     <>
//       {append !== undefined ? (
//         <>
//           <div>
//             <Typography
//               style={{ fontSize: fontSize }}
//               sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
//               align={align}
//             >
//               {children}
//             </Typography>
//           </div>
//           <div>{append}</div>
//         </>
//       ) : (
//         <Typography
//           style={{ fontSize: fontSize }}
//           sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }}
//           align={align}
//         >
//           {children}
//         </Typography>
//       )}
//     </>
//   )
// }

export default Typo;
