// ** MUI Import
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'

// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'

const FallbackSpinner = ({ title }) => {
  // ** Hook
  const theme = useTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,

        backgroundColor: hexToRGBA(theme.palette.background.default, 0.85),
        zIndex: 9999
      }}
    >
      <CircularProgress disableShrink sx={{ mt: -40 }} />
      {title !== undefined && (
        <Typography variant='h6' sx={{ mt: 2 }}>
          {title}
        </Typography>
      )}
    </Box>
  )
}

export default FallbackSpinner
