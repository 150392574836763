import BunnyVideo from "./VideoLessonBunny";
//import CloudflareVideo from "./VideoLessonCloudflare";

const VideoLibraryLesson = ({ course, videos_info, details, updateVideoCurrentTimeHandler, progressHandler }) => {
  const actualVideo = videos_info.find((item) => item.video_id === details?.video_source);

  // if (actualVideo?.provider === "bunny") {
  return (
    <BunnyVideo
      videoData={actualVideo}
      course={course}
      videos_info={videos_info}
      details={details}
      updateVideoCurrentTimeHandler={updateVideoCurrentTimeHandler}
      progressHandler={progressHandler}
    />
  );
  // }
  // return (
  //   <CloudflareVideo
  //     course={course}
  //     videos_info={videos_info}
  //     details={details}
  //     updateVideoCurrentTimeHandler={updateVideoCurrentTimeHandler}
  //     progressHandler={progressHandler}
  //   />
  // );
};
export default VideoLibraryLesson;
