import { useState } from 'react'
import React from 'react'

// ** MUI Imports
import Grid from '@mui/material/Grid'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import Select from '@mui/material/Select'
import Switch from '@mui/material/Switch'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import InputAdornment from '@mui/material/InputAdornment'
import FormControlLabel from '@mui/material/FormControlLabel'
import DialogContentText from '@mui/material/DialogContentText'

function EditUserInfo() {
  // ** States
  const [openEdit, setOpenEdit] = useState(false)

  // Handle Edit dialog
  const handleEditClose = () => setOpenEdit(false)
  const handleEditClickOpen = () => setOpenEdit(true)

  return (
    <>
      <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button disabled variant='contained' sx={{ mr: 2 }} onClick={handleEditClickOpen}>
          Editar
        </Button>
      </CardActions>
      {/* <Dialog
        open={openEdit}
        onClose={handleEditClose}
        aria-labelledby='user-view-edit'
        sx={{ '& .MuiPaper-root': { width: '100%', maxWidth: 650, p: [2, 10] } }}
        aria-describedby='user-view-edit-description'
      >
        <DialogTitle id='user-view-edit' sx={{ textAlign: 'center', fontSize: '1.5rem !important' }}>
          Editar Informações
        </DialogTitle>

        <DialogContent>
          <DialogContentText variant='body2' id='user-view-edit-description' sx={{ textAlign: 'center', mb: 7 }}>
            Updating user details will receive a privacy audit.
          </DialogContentText>
          <form>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Full Name' defaultValue={'' } />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Username'
                  defaultValue={''  }
                  InputProps={{ startAdornment: <InputAdornment position='start'>@</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth type='email' label='Billing Email' defaultValue={''  } />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='TAX ID' defaultValue='Tax-8894' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label='Contact' defaultValue={`+351 ${''  }`} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id='user-view-language-label'>Language</InputLabel>
                  <Select
                    label='Language'
                    defaultValue='English'
                    id='user-view-language'
                    labelId='user-view-language-label'
                  >
                    <MenuItem value='English'>English</MenuItem>
                    <MenuItem value='Spanish'>Spanish</MenuItem>
                    <MenuItem value='Portuguese'>Portuguese</MenuItem>
                    <MenuItem value='Russian'>Russian</MenuItem>
                    <MenuItem value='French'>French</MenuItem>
                    <MenuItem value='German'>German</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id='user-view-country-label'>Country</InputLabel>
                  <Select label='Country' defaultValue='USA' id='user-view-country' labelId='user-view-country-label'>
                    <MenuItem value='USA'>USA</MenuItem>
                    <MenuItem value='UK'>UK</MenuItem>
                    <MenuItem value='Spain'>Spain</MenuItem>
                    <MenuItem value='Russia'>Russia</MenuItem>
                    <MenuItem value='France'>France</MenuItem>
                    <MenuItem value='Germany'>Germany</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label='Use as a billing address?'
                  control={<Switch defaultChecked />}
                  sx={{ '& .MuiTypography-root': { fontWeight: 500 } }}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant='contained' sx={{ mr: 1 }} onClick={handleEditClose}>
            Submit
          </Button>
          <Button variant='outlined' color='secondary' onClick={handleEditClose}>
            Discard
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  )
}

export default EditUserInfo
