import React from "react";
import UserLayout from "src/layouts/UserLayout";
import MainUserLayout from "src/layouts/components/userProfileComponents/MainUserLayout";

function Profile() {
  return (
    <UserLayout>
      <MainUserLayout />
    </UserLayout>
  );
}

export default Profile;
