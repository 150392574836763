import React, { useMemo } from "react";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import { getUserAvatar } from "src/utils";

const CommentAvatar = ({ user }) => {
  const MemoCommentAvatar = useMemo(
    () => (
      <Box>
        <Avatar className="comments-avatar" alt={user?.name} src={getUserAvatar(user)} />
      </Box>
    ),
    [user]
  );

  return MemoCommentAvatar;
};

export default CommentAvatar;
