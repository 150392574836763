// ** ZOna para inserir o comentário num vídeo

import React, { useEffect, useState } from "react";

// ** MUI Import
import { Avatar, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { comment_create, comment_update, comment_delete } from "src/api/comments";
// ** Third Party Components
import toast from "react-hot-toast";
import { getUserAvatar } from "src/utils";
// ** Icons Imports
import Send from "mdi-material-ui/Send";

function CommentsInsert({
  auth,
  courseId,
  chapterId,
  lessonId,
  onAddNewComment,
  disabled = false,
  parentId = null,
  isReplay = false,
  onClose,
}) {
  // ** State
  const [comment, setComment] = useState("");
  const [writing, setWritting] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = () => {
    setComment("");
    setWritting(true);
    if (isReplay) onClose();
  };

  const sendComment = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const commentToSend = { user_id: auth.user.user_id, comment: comment };
      const response = await dbCreateComment(
        auth.user,
        courseId,
        chapterId,
        lessonId,
        commentToSend,
        isReplay,
        parentId
      );
      if (!response.status) {
        toast.error("Oops... Erro ao criar o comentário...", {
          position: "top-right",
          duration: 5000,
        });
        setIsLoading(false);
        return;
      }
      toast.success("Comentário adicionado com sucesso!", {
        position: "top-right",
      });
      setComment("");
      setIsLoading(false);
      onAddNewComment(response.data);
      if (isReplay) onClose();

      // Error Handling
    } catch (error) {
      toast.error("Oops... Erro ao criar o comentário...", {
        position: "top-right",
        duration: 5000,
      });
      setIsLoading(false);
    }
  }; //Guarda na variável o que foi comentado

  useEffect(() => {
    if (comment !== "") {
      setWritting(false);
    } else {
      setWritting(true);
    }
  }, [comment]); //verificação de a caixa está vazio ou não

  const dbCreateComment = (auth, courseId, chapterId, lessonId, commentToSend, is_replay, parent_id) => {
    return new Promise((resolve) => {
      try {
        comment_create(auth, courseId, chapterId, lessonId, commentToSend, is_replay, parent_id)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const isReadOnly = () => {
    if (disabled) return true;
    if (lessonId === null) return true;
    if (isLoading) return true;

    return writing;
  };

  return (
    <Box sx={{ ml: 0, mt: 0, mb: 4 }}>
      <Avatar
        alt="John Doe"
        sx={{ position: "absolute", width: 40, height: 40, mt: 3 }}
        src={getUserAvatar(auth.user)}
      />
      <Box sx={{ ml: 8, mt: 0 }}>
        <form noValidate autoComplete="off">
          <TextField
            id="standard-basic"
            label="Escreve aqui um comentário...."
            variant="outlined"
            sx={{ ml: 5, mb: 2, maxWidth: "92%" }}
            fullWidth
            multiline
            disabled={isLoading || disabled}
            value={comment}
            onChange={(e) => {
              if (e?.target?.value?.length > 1500)
                return toast.error("O comentário não pode ter mais de 1500 caracteres", { position: "top-right" });
              setComment(e.target.value);
            }}
          />
          {comment.length > 0 && (
            <div className="comments-buttons">
              {(!isReadOnly() || isReplay) && (
                <Button size="small" variant="text" color="secondary" onClick={handleReset} sx={{ ml: 3 }}>
                  Cancelar
                </Button>
              )}
              <Button
                variant="contained"
                endIcon={<Send />}
                disabled={isReadOnly()}
                type="submit"
                onClick={sendComment}
                sx={{ ml: 4 }}
              >
                Publicar
              </Button>
            </div>
          )}
        </form>
      </Box>
    </Box>
  );
}

export default CommentsInsert;
