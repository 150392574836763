// ** Zona para inserir a descrição do vídeo
import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

function Description({ content }) {
  if (content === undefined || content === null || content === "")
    return (
      <Box sx={{ p: 4 }}>
        <div style={{ fontSize: "1rem", lineHeight: 1.5 }}>
          <em>Não existem detalhes...</em>
        </div>
      </Box>
    );

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h6">Detalhes</Typography>
      <div
        className="lesson-html-content"
        style={{ fontSize: "1rem", lineHeight: 1.5 }}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Box>
  );
}

export default Description;
