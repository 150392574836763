// ** React Imports
import { Fragment } from "react";

// ** MUI Components
// import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

// Styled Components
const MaskImg = styled("img")(({ theme }) => ({
  zIndex: 0,
  top: "45%",
  width: "1200px",
  opacity: 0.5,
  position: "fixed",
  [theme.breakpoints.down("md")]: {
    //top: "50%",
    top: "50%",
    width: "900px",
  },
}));

const FooterIllustrationsV2 = (props) => {
  // ** Props
  const { image } = props;

  // ** Hook
  // const theme = useTheme();

  // ** Vars
  // const hidden = useMediaQuery(theme.breakpoints.down("md"));
  //const src = image || `/images/pages/auth-v2-register-mask-${theme.palette.mode}.png`
  const src = image || `/images/pages/home-callour-circle-gradient.svg`;
  // if (!hidden) {
  return (
    <Fragment>
      <MaskImg alt="mask" src={src} />
    </Fragment>
  );
  //  } else {
  //   return null;
  // }
};

export default FooterIllustrationsV2;
