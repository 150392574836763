import moment from "moment-timezone";

export const hasAccess = (endDate) => {
  if (endDate === null || endDate === undefined) {
    return true;
  }

  // Stupid Fix - ended_at with date of 1970-01-01
  if (moment.utc(endDate).local().isBefore(moment.utc("2023-01-01", "YYYY-MM-DD"))) {
    return true;
  }

  if (moment.utc(endDate).local().isBefore(moment.utc())) {
    return false;
  }
  return true;
};

export const accessEnded = (endDate) => {
  if (endDate === null || endDate === undefined) {
    return false;
  }

  // Stupid Fix - ended_at with date of 1970-01-01
  if (moment.utc(endDate).isBefore(moment.utc("2023-01-01", "YYYY-MM-DD"))) {
    return false;
  }

  if (moment.utc(endDate).isBefore(moment.utc())) {
    return true;
  }
  return false;
};
