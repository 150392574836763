import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import DrawerThumbnail from "./Thumbnail";
import Title from "./Title";
import Stats from "./Stats";
import Description from "./Description";
import CloseCircleOutline from "mdi-material-ui/CloseCircleOutline";
import moment from "moment-timezone";
import { hasAccess, accessEnded } from "src/@core/utils/course-date";

const isMobile = window.innerWidth <= 760;
export default function CourseDrawer({ data, visible, close }) {
  const [progress, setProgress] = useState(0);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (data?.courses_students?.lessonStatus) {
      const completedLessons = data?.courses_students?.lessonStatus?.filter(
        (lesson) => lesson.completed === true
      )?.length;
      const totalLessons = data?.active_lessons_count;
      const _progress = completedLessons / totalLessons || 0;

      let __progress = Math.round(_progress * 100);
      if (__progress > 100) __progress = 100;
      setProgress(__progress);
    }
    if (data?.courses_students?.ended_at) {
      const diffInDays = moment(data.courses_students.ended_at).diff(moment(), "days");
      if (diffInDays < 15) setEndDate(data.courses_students.ended_at);
    }
  }, [data]);

  const buttonTitle = (title_data) => {
    if (data?.courses_students?.started === true) return "Continuar a Ver";

    return "Iniciar Curso";
  };

  const CourseAccessTime = ({ endDate }) => {
    if (hasAccess(endDate) === false) {
      return "O acesso ao curso terminou";
    }
    return `O acesso ao curso termina ${moment.utc(endDate).local().fromNow()}`;
  };

  const DrawerContent = () => (
    <Box role="presentation">
      <IconButton size="large" sx={{}} onClick={close}>
        <CloseCircleOutline fontSize="inherit" />
      </IconButton>
      {endDate && (
        <Box sx={{ mt: 5, ml: 5, mr: 5 }}>
          <Alert severity="warning" sx={{ marginRight: 5 }}>
            <CourseAccessTime endDate={endDate} />
          </Alert>
        </Box>
      )}
      <Box sx={{ mt: 5 }}>
        <DrawerThumbnail image={data?.image} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <Button
          href={`/courses/${data?.course_id}`}
          size="small"
          variant="contained"
          disabled={accessEnded(endDate)}
        >
          {buttonTitle(data?.courses_students)}
        </Button>
      </Box>

      <Box sx={{ ml: 10, mr: 10 }}>
        <Title title={data?.title} />
        <Stats data={data} />
        <Description description={data?.description} />
        <Box sx={{ mt: 10 }}>
          <LinearProgress value={progress} variant="determinate" sx={{ height: 15 }} />
          <Typography variant="h6" color="text.secondary" align="center">{`${progress}% Completo`}</Typography>
        </Box>

        <Box sx={{ display: "flex", alingItem: "center", justifyContent: "right", mt: 5, mb: 10 }}>
          <Button href={`/courses/${data?.course_id}`} variant="contained" disabled={accessEnded(endDate)}>
            {buttonTitle(data?.courses_students)}
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: { width: isMobile ? "100%" : 750 },
        }}
        anchor="right"
        open={visible}
        onClose={close}
      >
        <DrawerContent />
      </Drawer>
    </div>
  );
}
