import React from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

// ** Custom Components
import CustomAvatar from "src/@core/components/mui/avatar";
import CustomChip from "src/@core/components/mui/chip";
import getAvatarPicture from "src/@core/components/profile";

// ** Context
import { useAuth } from "src/hooks/useAuth";

const roleColors = {
  admin: "error",
  editor: "info",
  author: "warning",
  maintainer: "success",
  subscriber: "primary",
};

const statusColors = {
  active: "success",
  pending: "warning",
  inactive: "secondary",
};

function ShowUserInfo() {
  const auth = useAuth();

  const renderUserAvatar = () => {
    return (
      <CustomAvatar
        alt="User Image"
        src={getAvatarPicture(auth?.user?.name)}
        variant="rounded"
        sx={{ width: 120, height: 120, mb: 4 }}
      />
    );
  };

  return (
    <>
      <CardContent sx={{ pt: 15, display: "flex", alignItems: "center", flexDirection: "column" }}>
        {renderUserAvatar()}
        <Typography variant="h6" sx={{ mb: 4 }}>
          {auth?.user?.name}
        </Typography>
      </CardContent>

      <CardContent>
        <Typography variant="h6">Detalhes</Typography>
        <Divider sx={{ mt: 4 }} />
        <Box sx={{ pt: 2, pb: 1 }}>
          <Box sx={{ display: "flex", mb: 2.7 }}>
            <Typography variant="subtitle2" sx={{ mr: 2, color: "text.primary" }}>
              E-mail:
            </Typography>
            <Typography variant="body2">{auth?.user?.email}</Typography>
          </Box>
          <Box sx={{ display: "flex", mb: 2.7 }}>
            <Typography variant="subtitle2" sx={{ mr: 2, color: "text.primary" }}>
              Status:
            </Typography>
            <CustomChip
              skin="light"
              size="small"
              label={"Verificado" /*data.status*/}
              //color={statusColors[data.status]}
              sx={{
                height: 20,
                fontSize: "0.75rem",
                fontWeight: 500,
                borderRadius: "5px",
                textTransform: "capitalize",
              }}
            />
          </Box>

          <Box sx={{ display: "flex", mb: 2.7 }}>
            <Typography sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}>Telefone:</Typography>
            <Typography variant="body2">{auth?.user?.phone}</Typography>
          </Box>
          <Box sx={{ display: "flex", mb: 2.7 }}>
            <Typography sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}>Linguagem:</Typography>
            <Typography variant="body2">Português</Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}>País:</Typography>
            <Typography variant="body2">{auth?.user?.country}</Typography>
          </Box>
        </Box>
      </CardContent>
    </>
  );
}

export default ShowUserInfo;
