import React from "react";

import BookOpenVariant from "mdi-material-ui/BookOpenVariant";
import Star from "mdi-material-ui/Star";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function Stats({ data }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", pt: 5 }}>
      <Box sx={{ display: "flex" }}>
        <BookOpenVariant color="secondary" sx={{ mt: 0.5 }} />
        <Typography variant="h6" sx={{ pl: 1, pt: 0 }}>
          {data?.lessons_count === 1 ? `${data?.lessons_count} Aula` : `${data?.active_lessons_count} Aulas`}
        </Typography>
      </Box>
      {/* <Box sx={{ display: 'flex' }}>
        <Star sx={{ color: '#f9d71c', mt: 1 }} />
        <Typography variant='h6' sx={{ pl: 1, pt: 0.4 }}>
          4.2(44)
        </Typography>
      </Box> */}
    </Box>
  );
}

export default Stats;
