export const getUserAvatar = (user) => {
  if (!user) return "";
  if (!!user.image) return user.image;
  if (!!user.picture) return user.picture;
  let name = user.name.replace(/ /g, "_").replace(/\s\s+/g, "-").replace(/#/g, "");

  // Outdated API Endpoint https://github.com/FBlade/SalesPark.io/issues/4304
  //return `https://avatars.dicebear.com/api/initials/${name}.svg`;

  return `https://api.dicebear.com/7.x/initials/svg?seed=${name}`;
};
