import React from "react";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";

import Thumbnail from "./Thumbnail";
import Title from "./Title";
import Stats from "./Stats";
import Footer from "./Footer";
import CourseDrawer from "../CourseDrawer";

function CourseCard({ data, loading }) {
  const [progress, setProgress] = React.useState(0);
  const [detailsDrawer, setDetailsDrawer] = useState({ visible: false, data: null });

  useEffect(() => {
    if (data?.courses_students?.lessonStatus) {
      const completedLessons = data?.courses_students?.lessonStatus?.filter(
        (lesson) => lesson.completed === true
      )?.length;
      const totalLessons = data?.active_lessons_count;
      const _progress = completedLessons / totalLessons || 0;

      let __progress = Math.round(_progress * 100);
        if (__progress > 100) __progress = 100;
      setProgress(__progress);
    }
  }, [data]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{
          mt: 0,
          ml: 0,
          mr: 0,
          display: "block",
          textTransform: "none",
          "&.MuiButton-outlined": { border: "1px solid #ebebeb" },
          "&.MuiButtonBase-root:hover": { bgcolor: "#ebebeb" },
          padding: 0,
        }}
        variant="outlined"
      >
        <Thumbnail
          thumbnail={data?.image}
          loading={loading}
          onClick={() => setDetailsDrawer({ visible: true, data: data })}
        />

        <Box sx={{ pl: 3, pr: 3, pt: 3, pb: 3 }}>
          <Title loading={loading} title={data?.title} />
          <Stats loading={loading} lessons={data?.active_lessons_count || data?.lessons_count} />
          <Box sx={{ pt: 4, ml: 2, mr: 2 }}>
            {loading ? (
              <Skeleton width="150px" animation="wave" />
            ) : (
              <LinearProgress value={progress} variant="determinate" />
            )}

            <Typography variant="body2" color="text.secondary">
              {loading ? <Skeleton width="80px" animation="wave" /> : `${progress}% Completo`}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mb: 0 }} />
        <Footer data={data} loading={loading} />
      </Card>
      <CourseDrawer
        visible={detailsDrawer.visible}
        data={detailsDrawer.data}
        close={() => setDetailsDrawer({ visible: false })}
      />
    </Grid>
  );
}

export default CourseCard;
