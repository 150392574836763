import React, { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Lessons from "./Lessons";

import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";

import { useTheme } from "@mui/material/styles";
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";
import moment from "moment/moment";
import { locale } from "src/lang/locale/moment_pt_PT";
import ChevronDown from "mdi-material-ui/ChevronDown";
import ArrowLeftThick from "mdi-material-ui/ArrowLeftThick";
import ArrowRightThick from "mdi-material-ui/ArrowRightThick";
import ChapterProgress from "./ChapterProgress";
import { isChapterAvailable, ChapterAvaliableIn } from "./helper";

moment.locale("pt", locale);

function MobilePlayList({
  videos_info,
  details,
  actualChapter,
  actualLesson,
  lessonStatusHandler,
  activeLessonHandler,
  onShowAttachmentsClick,
  lessonsStatus,
  courseAddedAt,
  courseEndDate,
  moveToPreviousLesson,
  moveToNextLesson,
  isFirstLesson,
  isLastLesson,
  showLessonNumbers,
}) {
  // ** Hook
  const theme = useTheme();

  const marginOffset = 50;
  const marginTopOffset = 66.55;
  const [offset, setOffset] = useState(0);
  const [expanded, setExpanded] = useState({}); //`panel${actualChapter}`

  useEffect(() => {
    const r = { ...expanded, [`panel${actualChapter}`]: true };
    setExpanded(r);
  }, []);

  useEffect(() => {
    const r = { ...expanded, [`panel${actualChapter}`]: true };
    setExpanded(r);
  }, [actualChapter]);

  const handleChange = (panel) => (event, isExpanded) => {
    const _panel = isExpanded ? true : false;
    const r = { ...expanded, [panel]: _panel };
    setExpanded(r);
  };

  // Styled component for Accordion component
  const Accordion = styled(MuiAccordion)(({ theme }) => ({
    boxShadow: "none !important",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "0px",
    "&:not(:last-of-type)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      margin: "2px",
    },
  }));

  const selectedColor = theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black;

  if (isFirstLesson === undefined) isFirstLesson = true;

  return (
    <>
      <Box sx={{ marginBottom: "70px" }}>
        <Card sx={{ mt: 2, borderRadius: 0, p: 3 }}>
          <Box>
            <div style={{ display: "flex", alignItems: "center", marginLeft: 20 }}>
              <h4>Conteúdo do curso</h4>
            </div>
          </Box>
          <div>
            {details?.map((e, i) => {
              const lessonsCounter = e?.lessons?.length;

              const chapterLessonsStatus = lessonsStatus.filter((el) =>
                e.lessons.find((lesson) => lesson.lesson_id === el.lesson_id)
              );

              let completedLessons = parseInt(
                chapterLessonsStatus.filter((el) => el.completed === true)?.length || 0
              );
              let completedPercentage = parseInt((completedLessons / lessonsCounter) * 100);
              if (completedPercentage > 100) completedPercentage = 100;
              if (completedPercentage < 0) completedPercentage = 0;

              return (
                <Accordion
                  className="mobile-side-bar-accordion"
                  key={e.chapter_id}
                  sx={{ p: 2 }}
                  expanded={expanded[`panel${e.chapter_id}`] || false}
                  onChange={handleChange(`panel${e.chapter_id}`)}
                >
                  <AccordionSummary
                    // style={{
                    //   backgroundColor: e.chapter_id === actualChapter ? hexToRGBA(selectedColor, 0.06) : "",
                    // }}
                    className={`mobile-side-bar-accordion-summary ${
                      e.chapter_id === actualChapter ? "mobile-side-bar-accordion-active" : ""
                    }`}
                    id={`panel-header-${e.chapter_id}`}
                    aria-controls={`panel-content-${e.chapter_id}`}
                    expandIcon={<ChevronDown />}
                  >
                    <div
                      className={`mobile-side-bar-accordion-summary-container ${
                        e.chapter_id === actualChapter ? "mobile-side-bar-accordion-active" : ""
                      }`}
                    >
                      <Typography variant="subtitle2" sx={{ mb: 1, fontSize: 16 }}>
                        <div className="chapter-container">
                          <div className="chapter-image-title-mobile">
                            {e.image !== null && e.image !== "" && e.image !== undefined && (
                              <img className="chapter-image-mobile" src={e.image} alt="chapter" />
                            )}
                            <div>
                              {e.title}
                              {isChapterAvailable(e, courseAddedAt) && (
                                <ChapterProgress completedPercentage={completedPercentage} />
                              )}
                              <ChapterAvaliableIn
                                chapter={e}
                                courseAddedAt={courseAddedAt}
                                courseEndDate={courseEndDate}
                              />
                            </div>
                          </div>
                        </div>
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="mobile-side-bar-accordion-details">
                    <div className="mobile-side-bar-accordion-details-container">
                      <Lessons
                        showLessonNumbers={showLessonNumbers}
                        isSmallDevice={true}
                        isChapterAvailable={isChapterAvailable(e, courseAddedAt)}
                        lessonsStatus={lessonsStatus}
                        videos_info={videos_info}
                        chapter={e?.chapter_id}
                        lessons={e?.lessons}
                        actualChapter={actualChapter}
                        actualLesson={actualLesson}
                        lessonStatusHandler={lessonStatusHandler}
                        activeLessonHandler={activeLessonHandler}
                        onShowAttachmentsClick={onShowAttachmentsClick}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </Card>
      </Box>
      <div
        className="mobile-lessons-nav"
        style={{
          backgroundColor: `${theme.palette.background.default}`,
          boxShadow: `0px 0px 0px ${hexToRGBA(selectedColor, 0)}, 0px -3px 6px ${hexToRGBA(selectedColor, 0.15)}`,
        }}
      >
        <div className="mobile-lessons-nav-container">
          <div
            className={`mobile-lessons-nav-left ${isFirstLesson ? "mobile-lessons-nav-left-disabled" : ""}`}
            style={{ borderRight: `1px solid ${hexToRGBA(selectedColor, 0.1)}` }}
            onClick={() => {
              if (!isFirstLesson) moveToPreviousLesson();
            }}
          >
            <ArrowLeftThick sx={{ mr: 2 }} /> Aula anterior
          </div>
          <div
            className={`mobile-lessons-nav-right ${isLastLesson ? "mobile-lessons-nav-right-disabled" : ""}`}
            style={{ borderLeft: `1px solid ${hexToRGBA(selectedColor, 0.1)}` }}
            onClick={() => {
              if (!isLastLesson) moveToNextLesson();
            }}
          >
            Próxima aula <ArrowRightThick sx={{ ml: 2 }} />
          </div>
        </div>
      </div>
    </>
  );
}

export default MobilePlayList;
