import React from "react";
const defaultLessonImage = "/images/default-lesson.png";

const TextLesson = ({ course, videos_info, details, updateCurrentTimeHandler, progressHandler }) => {
  setTimeout(() => {
    progressHandler(null, null, true);
  }, 2000);
  setTimeout(() => {
    progressHandler(0, 0);
  }, 1000);

  return (
    <div className="lesson-text-wrapper" style={{ borderRadius: 0, height: "100%", position: "inherit" }}>
      <div className="lesson-text-container" id="lesson-text-container">
        <div
          className="cover"
          style={{
            backgroundImage: `url("${details?.image || defaultLessonImage}")`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default TextLesson;
