import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import TrashCanOutline from "mdi-material-ui/TrashCanOutline";
import CustomChip from "src/@core/components/mui/chip";
import Chip from "@mui/material/Chip";
import moment from "moment-timezone";

const getFirstAndLastName = (name) => {
  let _name = name;
  try {
    if (!name.includes(" ")) return _name;

    let tmpArray = name.split(" ");

    const firstname = tmpArray[0];
    const lastname = tmpArray.pop();
    if (firstname !== "" && lastname !== "") {
      _name = firstname + " " + lastname;
      return _name;
    }
  } catch (err) {
    return _name;
  }
};

const CommentDate = ({ comment }) => {
  if (moment.utc(comment.date).isAfter(moment.utc())) return <small>Agora</small>;
  return <small>{moment.utc(comment.date).fromNow()}</small>;
};

const CommentInfo = ({ auth, comment, setDeleteCommentModal }) => {
  const isSelfComment = comment?.user_id === auth.user.user_id;

  const MemoCommentInfo = useMemo(
    () => (
      <Box sx={{ mt: 1, ml: 2 }}>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Typography sx={{ fontSize: "0.8rem", color: "text.secondary", mb: 1 }}>
            <strong>{getFirstAndLastName(comment?.user?.name)}</strong>
          </Typography>
          <Typography sx={{ color: "text.secondary", mb: 1 }} className="comment-date">
            <CommentDate comment={comment} />
          </Typography>
          {comment?.is_producer === true && (
            <CustomChip
              sx={{ fontSize: "0.750rem", height: "22px", mb: 1, ml: 2 }}
              label="Autor"
              skin="light"
              color="primary"
            />
          )}
          {isSelfComment && (comment?.replies?.length === 0 || comment?.is_reply === true) && (
            <Tooltip title="Apagar comentário" placement="top">
              <TrashCanOutline
                onClick={() => setDeleteCommentModal({ visible: true, id: comment._id })}
                className="comment-delete-icon"
                sx={{ color: "text.secondary", fontSize: "1rem", ml: 2, mb: 1 }}
              />
            </Tooltip>
          )}
          {/* {comment?.status === "rejected" && (
            <CustomChip
              sx={{ fontSize: "0.800rem", height: "22px", mb: 2, ml: 2 }}
              label="Comentário rejeitado"
              skin="light"
              color="error"
            />
          )} */}
          {comment?.status === "pending" && (
            <CustomChip
              sx={{ fontSize: "0.800rem", height: "22px", mb: 2, ml: 2 }}
              label="Aguarda aprovação"
              skin="light"
              color="warning"
            />
          )}
        </div>
        <Typography variant="subtitle1" sx={{ fontSize: "0.9rem" }}>
          {comment?.commentText}
        </Typography>
      </Box>
    ),
    [comment, setDeleteCommentModal, isSelfComment]
  );

  return MemoCommentInfo;
};

export default CommentInfo;
