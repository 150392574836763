import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Button from "@mui/material/Button";
import CommentView from "./CommentView";
import CommentsInsert from "./CommentsInsert";
import { comments_list } from "src/api/comments";
import LinearProgress from "@mui/material/LinearProgress";
import Icon from "src/@core/components/icon";
import CommentText from "mdi-material-ui/CommentText";
// ** Third Party Components
import toast from "react-hot-toast";
import moment from "moment-timezone";
const MAX_COMMENTS = 5;

// const _isChapterAvailable = (dripDays, courseAddedAt) => {
//   const avaliableDate = moment.utc(courseAddedAt).add(dripDays, "days");
//   if (moment.utc().isAfter(avaliableDate)) return true;
//   return false;
// };
const _isChapterAvailable = (chapter, courseAddedAt) => {
  //console.log("_isChapterAvailable", chapter, courseAddedAt);
  if (chapter.status_code === 0) return false;
  if (chapter.status_code === 1) return true;

  // Dripping
  if (chapter.status_code === 2 && chapter.drip_days !== undefined) {
    const avaliableDate = moment.utc(courseAddedAt).add(chapter.drip_days, "days");

    if (moment.utc().isAfter(avaliableDate)) return true;
  }

  // Avaliable On
  if (chapter.status_code === 3 && chapter.avaliable_on !== undefined) {
    if (moment.utc().isAfter(chapter.avaliable_on)) return true;
  }

  return false;
};

function Comments({
  courseId,
  chapterId,
  lessonId,
  auth,
  isSmallDevice,
  userCanReply,
  activeChapter,
  courseAddedAt,
}) {
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCommentsPart2, setShowCommentsPart2] = useState(false);
  const [tab, setTab] = useState("recents");
  const tabPaneStyle = {
    padding: 0,
    margin: 0,
  };
  useEffect(() => {
    getComments();
  }, [lessonId]);

  const commentsPart1 = comments?.slice(0, MAX_COMMENTS); // ["a", "b"]
  const commentsPart2 = comments?.slice(MAX_COMMENTS + 1); // ["d", "e", "f"]

  const getComments = async (showLoader = true, sort = tab) => {
    if (showLoader) setIsLoading(true);
    const response = await dbGetComments(auth.user, courseId, chapterId, lessonId, sort);
    if (!response.status) {
      toast.error("Oops... Erro ao carregar os comentário...", {
        position: "top-right",
        duration: 5000,
      });
      if (showLoader) setIsLoading(false);
      return;
    }

    setShowCommentsPart2(false);
    setComments(response.data);
    if (showLoader) setIsLoading(false);
  };

  const dbGetComments = (auth, courseId, chapterId, lessonId, order) => {
    return new Promise((resolve) => {
      try {
        comments_list(auth, courseId, chapterId, lessonId, order)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const handleChange = (newValue) => {
    setTab(newValue);
    getComments(true, newValue);
  };

  return (
    <>
      {_isChapterAvailable(activeChapter, courseAddedAt) && (
        <Card sx={{ pl: 3, pr: 3, mt: 2 }} style={{ borderRadius: 0 }}>
          <h4 style={{ marginLeft: 10, marginBottom: 0 }}>Comentários</h4>
          <Box sx={{ mt: 1, width: "100%", height: 4 }}>
            {isLoading && <LinearProgress style={{ height: 4 }} color="secondary" />}
          </Box>

          <div style={{ marginTop: 10 }}>
            <Button
              disabled={isLoading}
              style={{ marginLeft: 10, marginRight: 10 }}
              variant={tab === "recents" ? "contained" : "outlined"}
              size={"medium"}
              onClick={() => handleChange("recents")}
            >
              Mais recentes
            </Button>
            <Button
              disabled={isLoading}
              style={{ marginLeft: 10, marginRight: 10 }}
              variant={tab === "olds" ? "contained" : "outlined"}
              size="medium"
              onClick={() => handleChange("olds")}
            >
              Mais antigos
            </Button>
            {!isSmallDevice && (
              <Button
                disabled={isLoading}
                style={{ marginLeft: 10, marginRight: 10 }}
                variant={tab === "votes" ? "contained" : "outlined"}
                size="medium"
                onClick={() => handleChange("votes")}
              >
                Mais votados
              </Button>
            )}

            {!isSmallDevice && (
              <Button
                disabled={isLoading}
                style={{ marginLeft: 10, marginRight: 10 }}
                variant={tab === "comments" ? "contained" : "outlined"}
                size="medium"
                onClick={() => handleChange("comments")}
              >
                Mais comentários
              </Button>
            )}
          </div>
          {/* <TabContext value={tab}>
            <TabList onChange={handleChange} className={`${isSmallDevice ? "mobile-comments-tabs" : ""}`}>
              <Tab value="recents" label="Mais recentes" disabled={isLoading} />
              <Tab value="olds" label="Mais antigos" disabled={isLoading} />
              {!isSmallDevice && <Tab value="votes" label="Mais votados" disabled={isLoading} />}
              {!isSmallDevice && <Tab value="comments" label="Mais comentários" disabled={isLoading} />}
            </TabList>

            <TabPanel style={tabPaneStyle} value="recents"></TabPanel>
            <TabPanel style={tabPaneStyle} value="olds"></TabPanel>
            <TabPanel style={tabPaneStyle} value="votes"></TabPanel>
            <TabPanel style={tabPaneStyle} value="comments"></TabPanel>
          </TabContext> */}

          <Box className="comments-container">
            <Box>
              <CommentsInsert
                disabled={isLoading}
                courseId={courseId}
                chapterId={chapterId}
                lessonId={lessonId}
                auth={auth}
                onAddNewComment={(e) => getComments(false)}
              />
              {/*Zona de inserção de comentários*/}
              {commentsPart1.map((comment) => (
                <CommentView
                  userCanReply={userCanReply}
                  key={comment._id}
                  courseId={courseId}
                  chapterId={chapterId}
                  lessonId={lessonId}
                  auth={auth}
                  comment={comment}
                  onCommentUpdate={(e) => getComments(false)}
                />
              ))}
              {showCommentsPart2 && (
                <>
                  {commentsPart2.map((comment) => (
                    <CommentView
                      userCanReply={userCanReply}
                      key={comment._id}
                      courseId={courseId}
                      chapterId={chapterId}
                      lessonId={lessonId}
                      auth={auth}
                      comment={comment}
                      onCommentUpdate={(e) => getComments(false)}
                    />
                  ))}
                </>
              )}
              {commentsPart2.length > MAX_COMMENTS - 1 && showCommentsPart2 === false && (
                <Button variant="text" size="small" onClick={() => setShowCommentsPart2(true)}>
                  Ver mais comentários...
                </Button>
              )}

              {comments.length === 0 && (
                <Box sx={{ textAlign: "center", mt: 6 }}>
                  <div className="no-comments-container">
                    <div className="no-comments">
                      <CommentText />
                    </div>
                  </div>
                  <h3>Ainda não existem comentários...</h3>
                  <h5>Sê a primeira pessoa a comentar algo e começa um tópico para trocar ideias sobre a aula.</h5>
                </Box>
              )}
            </Box>
          </Box>
        </Card>
      )}
    </>
  );
}

export default Comments;
