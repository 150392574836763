// ** React Imports
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// ** Spinner Import
import Spinner from "src/@core/components/spinner";
// ** Hooks Import
import { useAuth } from "src/hooks/useAuth";

const stripTrailingSlash = (str) => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

const AuthGuard = ({ children }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const publicPages = ["/login", "/register", "/forgot-password", "/reset-password"];

  useEffect(
    () => {
      if (publicPages.includes(stripTrailingSlash(location.pathname))) {
        return <>{children}</>;
      }
      if (auth.loading === false && auth.user === null) {
        if (location.search.returnUrl !== undefined) {
          navigate("/login/?returnUrl=" + location.search.returnUrl);
        }
        //navigate("/login");

        //return <Navigate to="/login" />;
        // if (navigate.asPath !== "/") {
        //   navigate("/login/?returnUrl=FALTA");
        //   // navigate.replace({
        //   //   pathname: "/login",
        //   //   query: { returnUrl: navigate.asPath },
        //   // });
        // } else {
        //   navigate("/login");
        // }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (auth.loading) {
    return <Spinner />;
  }

  // console.log("AuthGuard", children);
  return <> {children}</>;
};

export default AuthGuard;
