import React from "react";
import Skeleton from "@mui/material/Skeleton";
const defaultThumbnail = "/images/default-course.png";
function Thumbnail({ name, thumbnail, loading, onClick }) {
  if (loading)
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        style={{ height: "150px", borderTopRightRadius: 8, borderTopLeftRadius: 8 }}
      />
    );

  return (
    <div style={{ cursor: "pointer" }}>
      <img
        onClick={onClick}
        alt={name}
        style={{ borderTopRightRadius: 8, borderTopLeftRadius: 8, width: "100%" }}
        src={thumbnail || defaultThumbnail}
      />
    </div>
  );
}

export default Thumbnail;
