import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";

const VideoLesson = ({ course, videos_info, details, updateVideoCurrentTimeHandler, progressHandler }) => {
  let playerRef = useRef(null);
  const [lastTimeUpdated, setLastTimeUpdated] = useState(0);

  const catchTimeUpdate = (progress) => {
    const player = playerRef?.current;

    if (player) {
      const currentTime = parseInt(progress.playedSeconds);
      const duration = parseInt(player.getDuration());
      progressHandler(currentTime, duration);

      if (Math.abs(currentTime - lastTimeUpdated) > 5) {
        setLastTimeUpdated(currentTime);
        updateVideoCurrentTimeHandler(duration, currentTime);
      }
    }
  };

  return (
    <>
      <div className="lesson-external-video-wrapper">
        {details?.video_source !== undefined && (
          <div className="lesson-external-video-container player-wrapper" id="lesson-external-video-container">
            <ReactPlayer
              ref={playerRef}
              url={details?.video_source}
              controls={true}
              embedOptions={{ wmode: "opaque" }}
              config={{
                youtube: {
                  playerVars: { showinfo: 0, rel: 0, wmode: "opaque" },
                },
              }}
              width="100%"
              height="100%"
              wmode="opaque"
              stopOnUnmount={true}
              className="react-player"
              onError={(e) => console.log("ReactPlayer ERROR", e)}
              onProgress={(progress) => catchTimeUpdate(progress)}
              onEnded={() => progressHandler(null, null, true)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default VideoLesson;
