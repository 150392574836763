import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

function Title({ title, loading }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'left' }}>
      <Typography variant='subtitle1' noWrap sx={{ display: 'block', ml: 2 }}>
        {loading ? <Skeleton width='140px' animation='wave' /> : title}
      </Typography>
    </Box>
  )
}

export default Title
